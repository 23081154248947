/**
 * @name Button - Primary component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Link } from "react-router-dom";
import { animated, config, useSpring } from "react-spring";

const IconArrow = (props: any) => {
  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      viewBox="0 0 16 8"
      style={props.style}
    >
      <g
        fill="none"
        fill-rule="evenodd"
        stroke="#FFF"
        stroke-linecap="round"
        stroke-width="1.5"
      >
        <path stroke-linejoin="round" d="M15,4 L12,1" />
        <path stroke-linejoin="round" d="M1,4 L15,4" />
        <path stroke-linejoin="round" d="M15,4 L12,7" />
      </g>
    </animated.svg>
  );
};

export interface IButtonPrimary {
  label: string;
  url: string;
  target?: string;
  show: boolean;
  delay: number;
}

const ButtonPrimary: preact.FunctionalComponent<IButtonPrimary> = ({
  delay,
  label,
  show,
  target,
  url
}) => {
  const [showState, set] = useState(false);

  useEffect(() => {
    set(show);
  }, [show]);

  const propsText = useSpring({
    config: config.stiff,
    from: {
      transform: "translate3d(0, 20px, 0)"
    },
    to: {
      transform: showState ? "translate3d(0, 0, 0)" : "translate3d(0, 20px, 0)"
    },
    delay
  });

  const propsIcon = useSpring({
    config: config.gentle,
    delay: showState ? delay + 100 : delay,
    from: {
      opacity: 0,
      transform: "translate3d(-10px, 0, 0)"
    },
    to: {
      opacity: showState ? 1 : 0,
      transform: showState ? "translate3d(0, 0, 0)" : "translate3d(10px, 0, 0)"
    }
  });

  if (url && target) {
    return (
      <a
        href={url}
        className="button button-primary"
        target="_blank"
        rel="noopener noreferrer"
        name={label}
      >
        <animated.span style={propsText}>{label}</animated.span>
        <IconArrow style={propsIcon} />
      </a>
    );
  }
  if (url) {
    return (
      <Link to={url} className="button button-primary">
        <animated.span style={propsText}>{label}</animated.span>
        <IconArrow style={propsIcon} />
      </Link>
    );
  }

  return (
    <button className="button button-primary" name={label}>
      <animated.span style={propsText}>{label}</animated.span>
      <IconArrow style={propsIcon} />
    </button>
  );
};

export default ButtonPrimary;
