/**
 * @name Application
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import Cookie from "cookies-js";
import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import ReactGA from "react-ga";

import Main from "@app/components/portfolio/Main";
import Background from "@app/components/portfolio/ui/background";
import StartupTip from "@app/components/portfolio/ui/startup-tip";
import { useViewportHeight, useWindowSize } from "@app/hooks/common";
import { delay } from "@app/utils/common";

// Set debug mode
if ((module as any).hot) {
  // tslint:disable-next-line:no-var-requires
  require("preact/debug");
}

export const COOKIE_NAME = "showintro";

const App: preact.FunctionalComponent = () => {
  const { height } = useWindowSize();

  useViewportHeight("#app", height);

  const [{ showStartup, showMain }, set] = useState({
    showStartup: false,
    showMain: false
  });

  const IS_NEW_VISITOR: boolean = Cookie.get(COOKIE_NAME) === undefined;

  const onInitalize = useCallback(() => {
    (async () => {
      ReactGA.initialize("UA-41791461-2");

      await delay(250);

      set({
        showStartup: IS_NEW_VISITOR,
        showMain: !IS_NEW_VISITOR
      });
    })();
  }, []);

  const onComplete = useCallback(() => {
    (async () => {
      Cookie.set(COOKIE_NAME, true);

      set({
        showStartup: true,
        showMain: true
      });

      await delay(100);

      set({
        showStartup: false,
        showMain: true
      });
    })();
  }, []);

  useEffect(() => {
    onInitalize();
  }, []);

  return (
    <div id="app">
      <Background />
      {showStartup && <StartupTip onExit={onComplete} />}
      <Main show={showMain} />
    </div>
  );
};

export default App;
