/**
 * @name UI - Background - WebGL component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

// Adapted from https://codepen.io/tr13ze/pen/pbjWwg

// @ts-nocheck
import { memo } from "preact/compat";
import { useCallback, useEffect } from "preact/hooks";

import { useWindowSize } from "@app/hooks/common";
import useAnimationFrame from "@app/hooks/common/useAnimationFrame";
import {
  ConeGeometry,
  DirectionalLight,
  Group,
  HemisphereLight,
  Mesh,
  MeshLambertMaterial
} from "three";

import { IBackground } from "@app/components/portfolio/ui/background";
import { fetchObject } from "@app/components/portfolio/ui/background/webgl-background/batch";
import skybox, {
  updateColor
} from "@app/components/portfolio/ui/background/webgl-background/skybox";
import stage from "@app/components/portfolio/ui/background/webgl-background/stage";

let camera;
let group;
let scene;
let counter = 0;

let initialized = false;

const geometryCone = new ConeGeometry(1, 1, 4);
const material = new MeshLambertMaterial({
  color: 0xffffff,
  flatShading: false
});

const Segment = (info, geometry) => {
  const section = new Group();

  // Shape
  for (let i = 0, len = info.length; i < len; i++) {
    const {
      scale,
      position: { x, z },
      rotation
    } = info[i];

    const itemScale = scale * 0.707;
    const item = new Mesh(geometry, material);

    // TODO: Adjust size...
    item.scale.x = itemScale;
    item.scale.y = itemScale;
    item.scale.z = itemScale;

    item.translateX(x);
    item.translateY(0);
    item.translateY(item.scale.y * 0.5);
    item.translateZ(z);

    item.rotation.y += ((rotation + 45) * Math.PI) / 180; // Rotate back to be straight

    section.add(item);
  }

  return section;
};

const items = [];

const WebGLBackground: preact.FunctionalComponent<IBackground> = ({
  bg_color1,
  bg_color2,
  camera_position,
  htmlElement,
  shape,
  shape_color,
  update
}) => {
  const { width, height } = useWindowSize();

  const reset = () => {
    if (group === undefined || camera === undefined) {
      return;
    }

    camera.position.set(0, 0, 0);
    camera.rotation.set(0, 0, 0);
    group.position.set(0, 0, 2000);

    // View #1: Drone - forward
    if (camera_position === "camera_1") {
      // Original position
    }

    // View #2: Drone - Top view
    if (camera_position === "camera_2") {
      camera.position.set(0, 500, 0);
      camera.lookAt(0, 0, -100);
    }

    // View #3: ...
    if (camera_position === "camera_3") {
      camera.position.set(0, 0, -500);
      camera.lookAt(250, 0, -50);
    }

    // View #4: ...
    if (camera_position === "camera_4") {
      camera.position.set(0, 250, 0);
      camera.lookAt(-100, 0, -200);
    }
  };

  const onInitialize = useCallback(() => {
    camera = stage.getCamera();
    scene = stage.getScene();

    group = new Group();
    group.position.z = 2000;
    scene.add(group);

    if (!initialized) {
      initialized = true;

      for (let i = 0; i < 5; i++) {
        const segmentData = fetchObject();
        const segment = Segment(segmentData, geometryCone);
        segment.segmentData = segmentData;
        segment.position.z = i * -2000;
        group.add(segment);

        items.push(segment);
      }

      scene.add(skybox);

      reset();
    }

    // Added different lights to cause effects
    const lights = [];

    // Highlight
    lights[0] = new DirectionalLight(0xffffff, 0.5);
    lights[0].position.set(0, 0, 10);

    // Mid tone
    lights[1] = new DirectionalLight(0xffffff, 0.75);
    lights[1].position.set(0.75, 1, 0.5);

    // Low ligh
    lights[2] = new DirectionalLight(0xffffff, 0.5);
    lights[2].position.set(-0.75, -1, 0.5);

    scene.add(lights[0]);
    scene.add(lights[1]);
    scene.add(lights[2]);

    const light = new HemisphereLight(0xffffff, 0xffffff, 0.8);
    scene.add(light);
  }, []);

  const onRender = useCallback(() => {
    if (group === undefined || camera === null) {
      return;
    }

    group.translateZ(1);
    counter += 0.005;
    group.position.y = -75 + Math.sin(counter) * -15;

    if (group.position.z > 4000) {
      const item = items.shift();
      items.push(item);

      items.map((segment, index) => {
        segment.position.z = index * -2000;
      });

      group.translateZ(-2000);
    }

    stage.render();
  }, []);

  useEffect(() => {
    stage.initialize({
      height,
      htmlElement,
      onInitialize,
      width
    });
  }, []);

  useAnimationFrame(onRender, update ? [] : null);

  useEffect(() => {
    stage.resize(width, height);
  }, [width, height]);

  useEffect(() => {
    if (group === undefined) {
      return;
    }

    updateColor(bg_color1, bg_color2);
  }, [bg_color1, bg_color2]);

  useEffect(() => {
    if (group === undefined) {
      return;
    }

    items.map(segment => {
      for (let i = 0, len = segment.children.length; i < len; i++) {
        const mesh = segment.children[i];
        mesh.material.color.set(shape_color);
      }
    });

    reset();
  }, [camera_position, shape_color]);

  return null;
};

export default memo(WebGLBackground);
