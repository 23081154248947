/**
 * @name Work detail component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { Fragment, h } from "preact";
import { memo } from "preact/compat";
import { useEffect, useRef, useState } from "preact/hooks";

import { work } from "@app/assets/data/main.json";
import {
  BlockAwards,
  BlockDesktop,
  BlockImage,
  BlockIntro,
  BlockOverview,
  BlockQuote,
  BlockTechnology,
  BlockText,
  BlockTwoCol,
  BlockVideo
} from "@app/components/portfolio/blocks";

import * as style from "./style.scss";

export interface IWorkDetailBody {
  state: string;
  intro: any;
  content: [];
  nextIndex: number;
  nextIntro: any;
}

const WorkDetailBody: preact.FunctionalComponent<IWorkDetailBody> = ({
  state,
  intro,
  content = [],
  nextIndex,
  nextIntro
}: {
  state: string;
  intro: any;
  content: [];
  nextIndex: number;
  nextIntro: any;
}) => {
  const [ready, set] = useState(false);

  const $ref = useRef(null);

  const renderBlock = (block: any) => {
    if (block === null || block.type === null) {
      return null;
    }

    switch (block.type) {
      case "video_desktop":
      case "image_desktop":
        return <BlockDesktop state={state} {...block} />;
      case "text":
        return <BlockText state={state} {...block} />;
      case "two_col":
        return <BlockTwoCol state={state} {...block} />;
      case "image":
        return <BlockImage height_for="image" state={state} {...block} />;
      case "video_inline":
        return (
          <BlockVideo state={state} {...block} className="is-full-width" />
        );
      case "technology":
        return <BlockTechnology state={state} {...block} />;
      case "awards":
        return <BlockAwards state={state} {...block} />;
      case "quote":
        return <BlockQuote state={state} {...block} />;
    }
  };

  useEffect(() => {
    set(true);
  }, []);

  return (
    <div className={style.inner} ref={$ref}>
      {ready && (
        <Fragment>
          {intro && (
            <BlockIntro
              {...intro}
              state={state}
              show={state === "enter"}
              delay={0}
            />
          )}
          {content && content.map(block => renderBlock(block))}
          {nextIntro && $ref.current && (
            <BlockOverview
              container={($ref.current as unknown) as HTMLElement}
              content={nextIntro}
              current={nextIndex + 1}
              old_intro={intro}
              state={state}
              total={work.length}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default memo(WorkDetailBody);
