/**
 * @name Hooks - Animation Frame
 *
 * Taken from https://css-tricks.com/using-requestanimationframe-with-react-hooks/
 */

import { useEffect, useRef } from "preact/hooks";

const useAnimationFrame = (callback: (time: number) => void): void => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef: any = useRef();
  const previousTimeRef: any = useRef();

  const animate = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      console.log("cancelling");

      cancelAnimationFrame(requestRef.current);
    };
  }, []); // Make sure the effect runs only once
};

export default useAnimationFrame;
