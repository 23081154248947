/**
 * @name UI - Background - Simple component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";

import { IBackground } from "@app/components/portfolio/ui/background";

import * as style from "./style.scss";

const SimpleBackground: preact.FunctionalComponent<IBackground> = ({
  bg_color1,
  bg_color2
}) => {
  const prop = {
    background: `linear-gradient(${bg_color1}, ${bg_color2})`
  };

  return <div className={style.background} style={prop} />;
};

export default memo(SimpleBackground);
