/**
 * @name Footer component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { Fragment, h } from "preact";
import { memo } from "preact/compat";
import { Transition } from "react-spring/renderprops";

import FooterContainer from "@app/components/portfolio/footer/footer-container";
import useWindowSize from "@app/hooks/common/useWindowSize";
import { TABLET_MD_WIDTH } from "@app/utils/common/Screen";

export interface IFooter {
  show: boolean;
  delay?: number;
}

const Footer: preact.FunctionalComponent<IFooter> = ({
  show = false,
  delay
}) => {
  const { width } = useWindowSize();

  return (
    <Fragment>
      {width && width >= TABLET_MD_WIDTH && (
        <Transition
          items={show}
          from={{ transform: "translate3d(0,25px,0)", opacity: 0 }}
          enter={{ transform: "translate3d(0,0,0)", opacity: 1 }}
          leave={{ transform: "translate3d(0,25px,0)", opacity: 0 }}
          trail={show ? 1000 : 0}
          delay={delay || show ? 500 : 0}
        >
          {value => value && (props => <FooterContainer springProps={props} />)}
        </Transition>
      )}
    </Fragment>
  );
};

export default memo(Footer);
