/**
 * @name Utils - screen
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

export const MOBILE_XS_WIDTH: number = 575;
export const MOBILE_SM_WIDTH: number = 576;
export const TABLET_MD_WIDTH: number = 768;
export const DESKTOP_LG_WIDTH: number = 992;
export const DESKTOP_XL_WIDTH: number = 1200;

export const getPointerType = (): string => {
  const body: any = document.querySelector("#app");
  return window.getComputedStyle(body, "::before").getPropertyValue("content");
};

export const isPointerCoarse = (): boolean => {
  return getPointerType().includes("coarse");
};

export const isPointerFine = (): boolean => {
  return getPointerType().includes("fine");
};

export const hasWebGL = ((): boolean => {
  // Taken from https://stackoverflow.com/questions/11871077/proper-way-to-detect-webgl-support
  try {
    const canvas: HTMLCanvasElement = document.createElement("canvas");

    // @ts-ignore
    return (
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (e) {
    return false;
  }
})();

export const isMobile = (): boolean => {
  return isPointerCoarse() && window.innerWidth < TABLET_MD_WIDTH;
};

export default {
  MOBILE_XS_WIDTH,
  MOBILE_SM_WIDTH,
  TABLET_MD_WIDTH,
  DESKTOP_LG_WIDTH,
  DESKTOP_XL_WIDTH,
  //
  getPointerType,
  isMobile,
  isPointerCoarse,
  isPointerFine
};
