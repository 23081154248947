/**
 * @name Block - Two Column component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";

import {
  BlockImage,
  BlockLogo,
  BlockMobile,
  BlockVideo
} from "@app/components/portfolio/blocks";

import style from "./style.scss";

export interface IBlockTwoCol {
  left: any;
  right: any;
}

const BlockTwoCol: preact.FunctionalComponent<IBlockTwoCol> = ({
  left,
  right
}) => {
  const getBlock = (value: any) => {
    switch (value.type) {
      case "logo":
        return <BlockLogo {...value} />;
      case "image":
        return <BlockImage height_for="twocol" {...value} />;
      case "video_inline":
        return <BlockVideo {...value} className="is-two-col" />;
      case "video_mobile":
      case "image_mobile":
        return <BlockMobile {...value} />;
    }
  };

  return (
    <section className={style.section}>
      <div className={style.col} style={{ backgroundColor: left.bg_color }}>
        {getBlock(left)}
      </div>
      <div className={style.col} style={{ backgroundColor: right.bg_color }}>
        {getBlock(right)}
      </div>
    </section>
  );
};

export default BlockTwoCol;
