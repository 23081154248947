/**
 * @name Block - Overview component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { Fragment, h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { Waypoint } from "react-waypoint";

import { IWaypoint } from "@app/components/common/waypoint";
import Footer from "@app/components/portfolio/footer";
import Slide from "@app/components/portfolio/routes/work-overview/slide";
import { dispatcher, updateFavicon } from "@app/utils/common";

export interface IBlockOverview {
  current: number;
  content: any;
  old_intro: any;
  state: string;
  total: number;
}

// Object definition
const BlockOverview: preact.FunctionalComponent<IWaypoint & IBlockOverview> = ({
  current,
  content,
  container,
  old_intro,
  state: propState,
  total
}) => {
  const [state, set] = useState(propState);

  //
  // Event handler: Set background color for next project
  const enterHandler = useCallback(() => {
    const {
      bg_color1,
      bg_color2,
      camera_position,
      favicon,
      shape,
      shape_color
    } = content;

    dispatcher.dispatch("color::change", {
      bg_color1,
      bg_color2,
      camera_position,
      delay: 0,
      shape_color,
      shape
    });

    set("enter");

    updateFavicon(favicon);
  }, [content, old_intro]);

  useEffect(() => {
    set(propState);
  }, [propState]);

  useEffect(() => {
    set("leave");
  }, []);

  // Event handler: Reset background color for current project
  const leaveHandler = useCallback(() => {
    const {
      bg_color1,
      bg_color2,
      camera_position,
      favicon,
      shape,
      shape_color
    } = old_intro;

    set("leave");

    dispatcher.dispatch("color::change", {
      bg_color1,
      bg_color2,
      camera_position,
      delay: 0,
      shape_color,
      shape
    });

    updateFavicon(favicon);
  }, [content, old_intro]);

  return (
    <Fragment>
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        onLeave={leaveHandler}
        topOffset="0%"
      >
        <Slide
          current={current}
          state={state}
          enableArrows={false}
          total={total}
          {...content}
        />
      </Waypoint>
      <Footer show={state === "enter"} />
    </Fragment>
  );
};

export default BlockOverview;
