/***
 * @name Hook - Mobile viewport
 * @description Cache app HTML element and height to set 1vh
 * so that correct height is always rendered for mobile.
 *
 * Taken from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */

import { useEffect, useState } from "preact/hooks";

interface IViewport {
  app: HTMLElement | unknown;
  vh: number;
}

const initialState: IViewport = {
  app: null,
  vh: 0
};

const useViewportHeight = (target: string, height: number = 0) => {
  const [{ app, vh }, set] = useState(initialState);

  useEffect(() => {
    const localVh: number = height * 0.01;

    if (app === null || vh === 0) {
      const localApp: HTMLElement | unknown = document.querySelector(
        target
      ) as HTMLElement;

      set({
        app: localApp,
        vh: localVh
      });
    } else if (localVh !== vh && app) {
      set({ app, vh: localVh });
    }
  }, [target, height]);

  // Set true viewport height via variable
  useEffect(() => {
    if (app) {
      (app as HTMLElement).style.setProperty("--vh", `${vh}px`);
    }
  }, [app, vh]);

  // Disable pinch to zoom for mobile
  useEffect(() => {
    document.addEventListener("gesturestart", e => {
      e.preventDefault();
    });

    return () => {
      document.removeEventListener("gesturestart", e => {
        e.preventDefault();
      });
    };
  }, []);
};

export default useViewportHeight;
