/**
 * @name Route - Work overview
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";

import WorkOverview from "@app/components/portfolio/routes/work-overview";
import { ISimpleRoute } from "@app/routes/simple-route";

const Route: preact.FunctionalComponent<ISimpleRoute> = ({ state }) => (
  <WorkOverview state={state} />
);

export default Route;
