/***
 * @name window size hook
 *
 * Taken from https://usehooks.com/useWindowSize/
 */

import { useEffect, useState } from "preact/hooks";

export interface WindowSize {
  width?: number;
  height?: number;
}

const useWindowSize = () => {
  const isClient = typeof window === "object";

  function getSize(): WindowSize {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
