/**
 * @name Application
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { Fragment, h } from "preact";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Transition } from "react-spring/renderprops";

import Footer from "@app/components/portfolio/footer";
import Header from "@app/components/portfolio/header";
import { AboutMe, NotFound, WorkDetail, WorkOverview } from "@app/routes";

// New approach with hooks
// https://codesandbox.io/embed/mjnwrk1o3p

// Old approach with render props
// https://github.com/react-spring/react-spring-examples/blob/renderprops/demos/renderprops/router/index.js

export interface IMain {
  show: boolean;
}

const Main: preact.FunctionalComponent<IMain> = ({ show }) => {
  return show ? (
    <Router>
      <Route
        render={({ location }) => {
          return (
            <Fragment>
              <Header show={true} url={location.pathname} />
              <Transition
                items={location}
                keys={location.pathname.split("/")[1]}
                from={{ life: 0 }}
                enter={{ life: 1 }}
                leave={{ life: -0.05 }}
                config={(item, state) =>
                  state === "leave"
                    ? { duration: 1000, delay: 0 }
                    : { duration: 1000, delay: 1000 }
                }
              >
                {(loc, state) => style => (
                  <Switch location={state === "update" ? location : loc}>
                    <Route
                      path="/"
                      exact={true}
                      render={() => WorkOverview({ state })}
                    />
                    <Route
                      path="/featured-work/:id"
                      render={props => {
                        return WorkDetail({
                          state,
                          // @ts-ignore
                          url: props.match.url
                        });
                      }}
                    />
                    <Route path="/about-me" render={() => AboutMe({ state })} />
                    <Route path="/" render={() => NotFound({ state })} />
                  </Switch>
                )}
              </Transition>
              <Footer
                show={
                  location.pathname === "/" ||
                  !(
                    location.pathname.includes("about-me") ||
                    location.pathname.includes("featured-work")
                  )
                }
              />
            </Fragment>
          );
        }}
      />
    </Router>
  ) : null;
};

export default Main;
