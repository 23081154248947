/**
 * @name Footer - Container component
 *
 * @version 1.0.0
 * @author Christopher Martin
 *
 * Adapted from https://codesandbox.io/s/54q5x6p92x
 */

import { h } from "preact";

import { footer } from "@app/assets/data/main.json";
import SocialLink from "@app/components/portfolio/footer/social-link";

export interface IFooterContainer {
  springProps: any;
}

const FooterContainer: preact.FunctionalComponent<IFooterContainer> = ({
  springProps
}) => {
  const { kvk, legal, links } = footer;

  return (
    <footer className="footer-container" style={{ ...springProps }}>
      <nav className="container footer-container__nav">
        <p>
          <span>&copy; {legal}&nbsp;</span>All rights reserved.&nbsp;
          {kvk}
        </p>
        <ul className="list">
          {links.map((item, index) => (
            <li key={index}>
              <SocialLink {...item} />
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
};

export default FooterContainer;
