/**
 * @name UI - Background - Plane object
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

const { floor, pow, random, sqrt } = Math;

const rnd = (min: number, max: number): number => {
  return min + floor(random() * (max - min));
};

// Random mesh layout generator
// This is quite processor intensive so the layouts are pooled
export const getLayout = ({
  height = 2000,
  leftMin = 0,
  leftMax = 0.425,
  maxLength = 50,
  minSize = 80,
  maxSize = 150,
  rightMin = 0.575,
  rightMax = 1,
  rotateMin = 20,
  rotateMax = 80,
  width = 600
}: {
  height?: number;
  leftMin?: number;
  leftMax?: number;
  maxLength?: number;
  minSize?: number;
  maxSize?: number;
  rightMin?: number;
  rightMax?: number;
  rotateMin?: number;
  rotateMax?: number;
  width?: number;
}) => {
  let loopProtection = 0;

  const offset = width * -0.5;
  const primitives = [];

  const xLeftMin = offset + width * leftMin;
  const xLeftMax = offset + width * leftMax;
  const xRightMin = offset + width * rightMin;
  const xRightMax = offset + width * rightMax;

  while (primitives.length < maxLength) {
    let overlapping = false;

    const { length } = primitives;
    const isLeft: boolean = length % 2 === 0;
    const scale = rnd(minSize, maxSize);

    const primitive: any = {
      position: {
        x: rnd(isLeft ? xLeftMin : xRightMin, isLeft ? xLeftMax : xRightMax),
        z: -(scale + rnd(0, height - scale * 2))
      },
      scale,
      rotation: rnd(rotateMin, rotateMax)
    };

    // 50% chance of shifting left size right x 2; right size left x 2
    // Used to make the path less straight
    primitive.position.x +=
      rnd(1, 2) % 2 === 0 ? (isLeft ? 2 * scale : -1 * scale) : 0;

    if (length > 0) {
      for (let j = 0; j < length; j++) {
        const otherPrimitive = primitives[j];

        const distance = sqrt(
          pow(otherPrimitive.position.x - primitive.position.x, 2) +
            pow(otherPrimitive.position.z - primitive.position.z, 2)
        );

        if (distance < primitive.scale + otherPrimitive.scale) {
          overlapping = true;
          break;
        }

        loopProtection++;
      }

      if (loopProtection > 500) {
        maxSize--;
        loopProtection = 0;

        if (maxSize < 5) {
          maxSize = 5;
        }
      }
    }

    if (!overlapping) {
      primitives.push(primitive);
    }

    if (loopProtection > 1000) {
      break;
    }
  }

  return primitives;
};
