/**
 * @name Block - Image component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";
import { useCallback, useState } from "preact/hooks";
import { Waypoint } from "react-waypoint";

import ImageLazy from "@app/components/common/lazy-load-image";

import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockImage {
  bg_color: string;
  object_fit: string;
  height_for?: string;
  url: string;
}

const BlockImage: preact.FunctionalComponent<IBlockImage & IWaypoint> = ({
  bg_color,
  container,
  object_fit = "cover",
  height_for = "twocol",
  url
}) => {
  const [enabled, setEnabled] = useState(true);

  const enterHandler = useCallback(() => {
    setEnabled(false);
  }, []);

  if (enabled) {
    return (
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        bottomOffset="60%"
      >
        <section
          className={
            height_for === "twocol"
              ? `${style.section} ${style.twocol}`
              : `${style.section}`
          }
          style={{ backgroundColor: bg_color }}
        >
          <div className={style.image}>
            <ImageLazy src={url} load={!enabled} object_fit={object_fit} />
          </div>
        </section>
      </Waypoint>
    );
  }

  return (
    <section
      className={
        height_for === "twocol"
          ? `${style.section} ${style.twocol}`
          : `${style.section}`
      }
      style={{ backgroundColor: bg_color }}
    >
      <div className={style.image}>
        <ImageLazy src={url} load={!enabled} object_fit={object_fit} />
      </div>
    </section>
  );
};

export default memo(BlockImage);
