/**
 * @name Button - Hamburger component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { animated, useTrail } from "react-spring";

import style from "./style.scss";

export interface IMenuHamburger {
  show: boolean;
  // TODO: This should be a function
  onClick: any;
}

const items = [1, 2, 3];
const config = { mass: 5, tension: 2000, friction: 200 };

const MenuHamburger: preact.FunctionalComponent<IMenuHamburger> = ({
  show,
  onClick
}) => {
  const trail = useTrail(items.length, {
    config,
    transform: show ? "scale3d(1, 1, 1)" : "scale3d(0, 1, 1)",
    from: { transform: "scale3d(0, 1, 1)" }
  });

  // TODO: Add enabled class if menu state is enabled
  return (
    <button className={`${style.btn}`} onClick={onClick}>
      {trail.map(({ ...rest }, index) => (
        <animated.span
          key={index}
          style={{
            ...rest
          }}
        />
      ))}
    </button>
  );
};

export default MenuHamburger;
