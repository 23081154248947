/**
 * @name Block - Logo component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockLogo {
  url: string;
}

const BlockLogo: preact.FunctionalComponent<IBlockLogo & IWaypoint> = ({
  container,
  url
}) => {
  const [enabled, setEnabled] = useState(true);

  const [props, setProps] = useSpring(() => ({
    opacity: 0,
    transform: "scale3d(1.2, 1.2, 1.2)"
  }));

  const enterHandler = useCallback(() => {
    setEnabled(false);
    setProps({ opacity: 1, transform: "scale3d(1, 1, 1)", delay: 250 });
  }, []);

  if (enabled) {
    return (
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        bottomOffset="60%"
      >
        <div className={style.section}>
          <animated.img src={url} alt="" style={props} />
        </div>
      </Waypoint>
    );
  }

  return (
    <div className={style.section}>
      <animated.img src={url} alt="" style={props} />
    </div>
  );
};

export default BlockLogo;
