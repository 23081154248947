/**
 * @name Header - Link component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

// @ts-nocheck
import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { NavLink } from "react-router-dom";
import { animated, useSpring } from "react-spring";

import style from "./style.scss";

export interface IHeaderLink {
  delay: number;
  href: string;
  label: string;
  show: boolean;
  subtext?: string;
  type: string;
}

const HeaderLink: preact.FunctionalComponent<IHeaderLink> = ({
  delay,
  href,
  label,
  show,
  subtext,
  type
}) => {
  const inlineStyle = { "--delay": `${show ? delay + 200 : 0}ms` };

  const [ready, setReady] = useState(false);
  const [props, set] = useSpring(() => ({
    transform: "translate3d(0px, 100%, 0px)"
  }));

  useEffect(() => {
    setReady(true);

    set({
      transform: show
        ? "translate3d(0px, 0px, 0px)"
        : "translate3d(0px, 100%, 0px)",
      delay
    });
  }, []);

  if (type === "external") {
    return (
      <a
        aria-label={label}
        className={style.link}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={style.body}>
          <animated.span className={style.text} style={props}>
            {label}
          </animated.span>
        </span>
        {subtext && (
          <span className={style.subtext}>
            <animated.span className={style.text} style={props}>
              {subtext}
            </animated.span>
          </span>
        )}
      </a>
    );
  }

  return (
    <NavLink
      activeStyle={inlineStyle}
      activeClassName={ready && show ? style.active : ""}
      aria-label={label}
      className={type === "button" ? style.button : style.link}
      exact={true}
      to={href}
    >
      <span className={style.body}>
        <animated.span className={style.text} style={props}>
          {label}
        </animated.span>
      </span>
      {subtext && (
        <span className={style.subtext}>
          <animated.span className={style.text} style={props}>
            {subtext}
          </animated.span>
        </span>
      )}
    </NavLink>
  );
};

export default HeaderLink;
