/**
 * @name Route - Not found component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";
import { useEffect, useState } from "preact/hooks";

import LineText from "@app/components/common/transitions/line-text";
import { ButtonPrimary } from "@app/components/portfolio/buttons";
import { ISimpleRoute } from "@app/routes/simple-route";
import { dispatcher, updateFavicon } from "@app/utils/common";

import * as style from "./style.scss";

const title = ["Well this is", "unfortunate!"];
const body = [
  "Looks like you’re lost but fear not...",
  "",
  "You could wait for this path to take you",
  "back home or click the following button",
  "to see how we can make awesomeness",
  "together."
];

const ENTER_DELAY: number = 1000;

const NotFound: preact.FunctionalComponent<ISimpleRoute> = ({ state }) => {
  const [show, set] = useState(false);

  useEffect(() => {
    dispatcher.dispatch("color::change", {
      bg_color1: "#264C8C",
      bg_color2: "#00173B",
      camera_position: "camera_1",
      delay: 1000,
      shape: "pyramid",
      shape_color: "#1B3D75"
    });

    updateFavicon("/assets/icons/not-found/favicon.ico");
  }, []);

  useEffect(() => {
    set(state === "enter" || state === "update");
  }, [state]);

  return (
    <section className={style.section}>
      <div className="container fullheight">
        <div className="row fullheight">
          <div className={`col-md-6 ${style.inner}`}>
            <div className={style.body}>
              <h2 className="line-text">
                <LineText
                  delay={show ? ENTER_DELAY : 0}
                  lines={title}
                  show={show}
                />
              </h2>
              <p className="line-text">
                <LineText
                  delay={show ? ENTER_DELAY + 200 : 100}
                  lines={body}
                  show={show}
                />
              </p>
              <ButtonPrimary
                delay={show ? ENTER_DELAY + 750 : 200}
                label="Take me home"
                show={show}
                url="/"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(NotFound);
