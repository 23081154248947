/**
 * @name UI - Availability Notification component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { animated, config, useSpring } from "react-spring";

import style from "./style.scss";

export interface IAvailabilityNotification {
  delay?: number;
  show: boolean;
}

const AvailabilityNotification: preact.FunctionalComponent<IAvailabilityNotification> = ({
  delay = 0,
  show
}) => {
  const props = useSpring({
    config: config.stiff,
    delay,
    from: { opacity: 0 },
    opacity: show ? 1 : 0
  });

  return (
    <div className={style.notification}>
      <animated.p style={props}>
        Available <strong>May 2020</strong> for new projects.
      </animated.p>
    </div>
  );
};

export default AvailabilityNotification;
