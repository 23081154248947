/**
 * @name Work overview - Slide controls component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";

import Counter from "@app/components/portfolio/routes/work-overview/slide-controls/counter";

import style from "./style.scss";

export interface ISlideControls {
  autoPlay?: boolean;
  onControlUpdate: (slide: number, playNext: boolean) => void;
  selected: number;
  show: boolean;
  total: number;
}

const SlideControls: preact.FunctionalComponent<ISlideControls> = ({
  autoPlay = false,
  onControlUpdate,
  selected,
  show,
  total
}) => {
  const [ready, set] = useState(false);

  const props = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: ready && show ? 1 : 0
    }
  });

  const propsValue = useSpring({
    transform: autoPlay ? "scale3d(1, 1, 1)" : "scale3d(0, 1, 1)"
  });

  const backClickHandler = useCallback(() => {
    if (selected - 1 > -1) {
      onControlUpdate(selected - 1, false);
    }
  }, [selected]);

  const nextClickHandler = useCallback(() => {
    if (selected + 1 < total) {
      onControlUpdate(selected + 1, false);
    }
  }, [selected]);

  const autoPlayNextHandler = useCallback(() => {
    if (selected + 1 < total) {
      onControlUpdate(selected + 1, true);
    }
  }, [selected]);

  useEffect(() => {
    if (show) {
      set(true);
    }
  }, [show]);

  return (
    <animated.div className={style.controls} style={props}>
      <button
        onClick={backClickHandler}
        className={style.btn}
        disabled={selected === 0}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#FFF"
            stroke-linecap="round"
          >
            <path stroke-linejoin="round" d="M8,8 L12,4" />
            <path stroke-linejoin="round" d="M8,8 L12,12" />
          </g>
        </svg>
      </button>
      <animated.span className={style.progress} style={propsValue}>
        {autoPlay && ready && (
          <Counter
            selected={selected}
            duration={8000}
            onComplete={autoPlayNextHandler}
          />
        )}
      </animated.span>
      <button
        onClick={nextClickHandler}
        className={style.btn}
        disabled={selected === total - 1}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="#FFF"
            stroke-linecap="round"
          >
            <path stroke-linejoin="round" d="M12,8 L8,4" />
            <path stroke-linejoin="round" d="M12,8 L8,12" />
          </g>
        </svg>
      </button>
    </animated.div>
  );
};

export default SlideControls;
