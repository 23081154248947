/**
 * @name Work overview - Slide component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import * as d3Ease from "d3-ease";
import { Fragment, h } from "preact";
import { animated, useSprings } from "react-spring";
import { SpringConfig } from "react-spring/renderprops";

const customEnter = d3Ease.easeBackOut.overshoot(1.5);
const customLeave = d3Ease.easeBackIn.overshoot(2);

interface ILineText {
  delay: number;
  lines: string[];
  show: boolean;
}

const LineText: preact.FunctionalComponent<ILineText> = ({
  delay,
  lines,
  show
}: {
  config?: SpringConfig;
  delay: number;
  lines: string[];
  show: boolean;
}) => {
  const numLines = lines.length - 1;
  const to = (i: number) => ({
    transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 100%, 0)",
    delay: show ? i * 100 + delay : (numLines - i) * 100 + delay
  });

  const springs = useSprings(
    lines.length,
    lines.map((item, i) => ({
      config: {
        duration: 400,
        easing: show ? customEnter : customLeave
      },
      from: { transform: "translate3d(0, 100%, 0)" },
      ...to(i)
    }))
  );

  return (
    <Fragment>
      {springs.map((props, index) => (
        <span key={index}>
          <animated.span style={props}>{lines[index]}</animated.span>
        </span>
      ))}
    </Fragment>
  );
};

export default LineText;
