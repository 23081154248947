/**
 * @name UI - Work indicator component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { animated, config, useSpring } from "react-spring";

import style from "./style.scss";

export interface IWorkIndicator {
  current: number;
  delay: number;
  show: boolean;
  total: number;
}

const WorkIndicator: preact.FunctionalComponent<IWorkIndicator> = ({
  current,
  delay,
  show,
  total
}) => {
  const props = useSpring({
    config: config.stiff,
    delay,
    transform: show ? "translate3d(0, 0%, 0)" : "translate3d(0, 100%, 0)"
  });

  return (
    <small className={style.indicator}>
      <animated.span style={props}>
        <strong className={style.current}>Project {current}</strong> of {total}
      </animated.span>
    </small>
  );
};

export default WorkIndicator;
