/**
 * @name Common - Lazy load image component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useState } from "preact/hooks";

import style from "./style.scss";

export interface IImageLazy {
  src: string;
  load?: boolean;
  object_fit?: string;
}

// @ts-nocheck
const ImageLazy: preact.FunctionalComponent<IImageLazy> = ({
  object_fit,
  load,
  src
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      alt=""
      src={load ? src : ""}
      className={`${style.image} ${loaded && style.transition}`}
      style={{ objectFit: object_fit || "none" }}
      onLoad={() => setLoaded(true)}
    />
  );
};

export default ImageLazy;
