/**
 * @name UI - About portrait component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { Fragment, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";

import { delay } from "@app/utils/common";

import * as style from "./style.scss";

export interface IAboutPortrait {
  show: boolean;
  delay: number;
}

const SVGBody = () => (
  <g fill="none" fill-rule="evenodd">
    <g transform="translate(24.154 23.785)">
      <path
        fill="#000"
        stroke="#000"
        d="M129.730769,149.614891 L191.24958,167.475599 C169.322543,196.167412 134.745875,214.676607 95.8461538,214.676607 C57.2938848,214.676607 22.9877876,196.496584 1.03330637,168.241982 L65.1923077,149.614891 L129.730769,149.614891 Z"
      />
      <path
        fill="#FFF"
        stroke="#000"
        d="M127.615385,113.400415 L127.384615,139.870742 L129.730769,149.614891 L156.617623,157.420245 C147.519203,174.211346 124.464711,186.136269 97.4615385,186.136269 C70.5598701,186.136269 47.5772524,174.300828 38.4086477,157.609391 C38.3741433,157.546576 38.3398345,157.483692 38.305722,157.42074 L65.1923077,149.614891 L67,139.870742 L67,113.400415 L127.615385,113.400415 Z"
      />
      <path
        fill="#000"
        fill-opacity=".1"
        d="M127.55926,119.845364 L127.384615,139.870742 L129.730769,149.614891 L156.617623,157.420245 C147.519203,174.211346 124.464711,186.136269 97.4615385,186.136269 C86.8121884,186.136269 76.7769801,184.281576 67.9697403,181.005153 C94.0944324,161.816926 107.591261,152.223561 108.461538,152.223561 C109.384615,152.223561 109.384615,148.13153 108.461538,139.947467 L108.461538,139.947467 L127.384615,119.845364 L127.55926,119.845364 Z"
      />
      <line
        x1="101.846"
        x2="108.462"
        y1="163.579"
        y2="176.008"
        stroke="#000"
        stroke-linecap="round"
        transform="matrix(-1 0 0 1 210.308 0)"
      />
      <line
        x1="86.615"
        x2="93.231"
        y1="163.579"
        y2="176.008"
        stroke="#000"
        stroke-linecap="round"
      />
      <path
        fill="#FFF"
        stroke="#000"
        d="M60.3896846,96.5989313 C59.1439606,97.0081344 57.9131914,96.1897282 56.6973769,94.1437126 C54.1844093,89.9148018 53.1885296,82.7472671 51.9281461,71.4329394 C51.5731449,68.2461315 52.4235164,65.8905389 53.4666077,65.1414414 C55.3353161,63.7994257 57.3865981,64.0551777 59.6204538,65.9086973"
      />
      <path
        fill="#FFF"
        stroke="#000"
        d="M141.620454,96.4822814 C140.37473,96.8914845 139.143961,96.0730782 137.928146,94.0270626 C135.415179,89.7981518 134.419299,82.6306171 133.158915,71.3162895 C132.803914,68.1294815 133.654286,65.773889 134.697377,65.0247915 C136.566085,63.6827758 138.976342,64.1942797 141.928146,66.5593032"
        transform="matrix(-1 0 0 1 275.005 0)"
      />
      <path
        fill="#000"
        stroke="#000"
        d="M60.4615385,14.8847635 L68.1538462,11.0484843 L64.3076923,4.91043744 L79.4615385,6.59840032 L79.4615385,0 L118.769231,10.1277772 L124.307692,8.7467167 L128.923077,16.8796287 L137.728235,26.0866989 L140.769231,42.0456206 L140.769231,61.7563035 C140.670062,64.4384227 139.900831,67.433318 138.461538,70.7409894 C137.395736,73.1903343 136.046951,75.0317484 134.461538,78.1066456 L58.6153846,78.1066456 C57.0904618,76.2179179 55.8960198,73.2511953 55.0769231,70.7409894 C54.5188818,69.0308149 54.3137536,67.0099012 54.4615385,64.6782485 L54.4615385,43.1197788 L48.9230769,29.3091735 L57.5384615,21.9435173 L56.1897731,13.8106053 L85.0769231,19.9486521"
      />
      <path
        fill="#FFF"
        d="M62,102.965735 C67.6923077,125.369606 76.9146623,143.016491 97.3846154,143.016491 C117.230769,143.016491 130.769231,117.697047 132.615385,102.965735 C132.63509,102.8085 134.726346,88.8729049 134.461538,75.1910734 C134.381667,71.0643434 133.509872,67.1769138 131.846154,63.5287844 C134.06446,51.7690143 133.500357,44.1476062 130.153846,40.6645601 C123.076923,33.2989039 115.846154,34.0661598 114.461538,34.0661598 C113.538462,34.0661598 109.025641,34.5265133 100.923077,35.4472203 C94.4615385,34.5265133 91.2307692,34.0661598 91.2307692,34.0661598 C75.3846154,30.5367829 67.5384615,31.7643922 63.0769231,41.5852671 C61.5384615,49.8716303 62.6153846,59.2321517 62.6153846,59.2321517 C58.6153846,63.9891379 56.3076923,80.5618643 62,102.965735 Z"
      />
      <path
        fill="#D8D8D8"
        fill-opacity=".4"
        d="M114.461538,34.0661598 C115.846154,34.0661598 123.076923,33.2989039 130.153846,40.6645601 C133.500357,44.1476062 134.06446,51.7690143 131.846154,63.5287844 C133.509872,67.1769138 134.381667,71.0643434 134.461538,75.1910734 C134.726346,88.8729049 132.63509,102.8085 132.615385,102.965735 C130.769231,117.697047 117.230769,143.016491 97.3846154,143.016491 L97.3846154,143.016491 L100.923077,127.21102 L100.923077,122.5971 L98.0145938,122.602262 L98.0145938,116.315987 L94.8461538,116.315987 L98.0145938,105.267503 L103.653846,101.814851 L107.076923,101.814851 L106.423077,98.9760047 L101.846154,92.5310556 L101.846154,75.1910734 L103.538462,70.8688511 L111.384615,68.8995754 L100.923077,35.4472203 L101.38655,35.3946133 C109.197883,34.5089776 113.556213,34.0661598 114.461538,34.0661598 Z"
      />
      <path
        stroke="#000"
        d="M62,102.965735 C67.6923077,125.369606 76.9146623,143.016491 97.3846154,143.016491 C117.230769,143.016491 130.769231,117.697047 132.615385,102.965735 C132.63509,102.8085 134.726346,88.8729049 134.461538,75.1910734 C134.381667,71.0643434 133.509872,67.1769138 131.846154,63.5287844 C134.06446,51.7690143 133.500357,44.1476062 130.153846,40.6645601 C123.076923,33.2989039 115.846154,34.0661598 114.461538,34.0661598 C113.538462,34.0661598 109.025641,34.5265133 100.923077,35.4472203 C94.4615385,34.5265133 91.2307692,34.0661598 91.2307692,34.0661598 C75.3846154,30.5367829 67.5384615,31.7643922 63.0769231,41.5852671 C61.5384615,49.8716303 62.6153846,59.2321517 62.6153846,59.2321517 C58.6153846,63.9891379 56.3076923,80.5618643 62,102.965735 Z"
      />
      <line
        x1="129.846"
        x2="132"
        y1="160.05"
        y2="149.768"
        stroke="#000"
        stroke-linecap="round"
        transform="matrix(-1 0 0 1 261.846 0)"
      />
      <line
        x1="63.077"
        x2="65.231"
        y1="160.05"
        y2="149.768"
        stroke="#000"
        stroke-linecap="round"
      />
      <g
        stroke="#000"
        stroke-linecap="round"
        transform="translate(84.77 113.088)"
      >
        <path
          stroke-linejoin="round"
          d="M27.1542189,0.0052231114 C24.4615385,1.38700222 21.7867328,2.38153295 20.3846154,2.76734417 C13.1538462,4.75698627 0.148790106,2.85912774 1.59161573e-12,2.76734417"
        />
        <path
          d="M7.08481001,9.50881122 C6.97556554,9.01454189 8.00120657,8.65648916 10.1617331,8.43465302 C12.1517125,8.23032813 14.6706961,8.25796656 16.3155792,8.43465302 C18.5523238,8.67491478 19.5779649,9.03296751 19.3925023,9.50881122"
          transform="matrix(1 0 0 -1 0 17.8)"
        />
      </g>
      <path
        stroke="#000"
        stroke-linecap="round"
        d="M90.2566491,95.7535302 C90.4104952,96.0604325 88.8588,96.9319815 88.4104952,98.5156512 C87.9357231,100.192819 87.7951106,100.740693 88.4104952,101.431223 C89.0258798,102.121754 91.7951106,102.198479 92.7437091,102.735558 C93.3761082,103.093611 94.8804215,103.937593 97.2566491,105.267503 L98.8720337,105.267503 C101.248261,103.937593 102.13719,103.093611 102.769589,102.735558 C103.718188,102.198479 106.02588,102.121754 106.641264,101.431223 C107.256649,100.740693 107.116037,100.192819 106.641264,98.5156512"
      />
      <path
        stroke="#000"
        stroke-linecap="round"
        d="M137.846154,86.6999111 C135.589744,85.8807679 134.461538,84.6531586 134.461538,83.017083 C134.461538,81.5329848 134.461538,80.3565258 134.461538,79.4877061"
        transform="matrix(-1 0 0 1 272.308 0)"
      />
      <path
        stroke="#000"
        stroke-linecap="round"
        d="M59.0769231,86.6999111 C56.8205128,85.8807679 55.6923077,84.6531586 55.6923077,83.017083 C55.6923077,81.5329848 55.6923077,80.3565258 55.6923077,79.4877061"
      />
      <g transform="translate(103.538 68.9)">
        <ellipse cx="10.923" cy="7.519" fill="#000" rx="2" ry="1.995" />
        <path
          stroke="#000"
          d="M3.53846154,9.66742372 C5.89781715,6.90530266 8.43627869,5.52424213 11.1538462,5.52424213 C14.0769231,5.52424213 17.025641,7.212205 20,10.5881307"
        />
        <path
          stroke="#000"
          stroke-linecap="round"
          stroke-opacity=".1"
          d="M7.84615385,12.1226424 C9.05123365,12.4295448 10.2307208,12.582996 11.3846154,12.582996 C12.5385099,12.582996 13.7179971,12.4295448 14.9230769,12.1226424"
        />
        <path
          stroke="#000"
          stroke-linecap="round"
          d="M0.115384615,3.83627925 C0.115384615,3.83627925 6.86806431,0.515855564 11.5384615,0.15345117 C14.6520597,-0.0881517589 18.7546238,0.525652922 23.8461538,1.99486521"
          transform="matrix(-1 0 0 1 23.962 0)"
        />
      </g>
      <g transform="translate(65.077 68.9)">
        <ellipse cx="13.538" cy="5.985" fill="#000" rx="2" ry="1.995" />
        <path
          stroke="#000"
          d="M6.15384615,7.97946085 C8.51320176,5.21733978 11.0516633,3.83627925 13.7692308,3.83627925 C16.6923077,3.83627925 19.6410256,5.52424213 22.6153846,8.90016787"
        />
        <path
          stroke="#000"
          stroke-linecap="round"
          stroke-opacity=".1"
          d="M10.3076923,10.2812284 C11.5127721,10.5881307 12.6922593,10.7415819 13.8461538,10.7415819 C15.0000484,10.7415819 16.1795356,10.5881307 17.3846154,10.2812284"
        />
        <path
          stroke="#000"
          stroke-linecap="round"
          d="M0.115384615,3.83627925 C0.115384615,3.83627925 6.86806431,0.515855564 11.5384615,0.15345117 C14.6520597,-0.0881517589 18.7546238,0.525652922 23.8461538,1.99486521"
        />
      </g>
    </g>
  </g>
);

const AboutPortrait: preact.FunctionalComponent<IAboutPortrait> = ({
  delay,
  show
}) => {
  // Hook: Animation
  const [propsBackground, setBackground] = useSpring(() => ({
    transform: "scale3d(0, 0, 0)"
  }));

  const [propsPhoto, setPhoto] = useSpring(() => ({
    transform: "translate3d(0, 100%, 0)"
  }));

  // Hook: On Mount - trigger animation
  // @ts-ignore
  useEffect(async () => {
    setBackground({
      transform: show ? "scale3d(1, 1, 1)" : "scale3d(0, 0, 0)",
      delay: delay + (show ? 0 : 250)
    });
    setPhoto({
      transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 100%, 0)",
      delay: delay + (show ? 250 : 0)
    });
  }, [show]);

  return (
    <div className={style.portrait}>
      <div className={style.container}>
        <animated.span className={style.background} style={propsBackground} />
      </div>
      <animated.svg
        xmlns="http://www.w3.org/2000/svg"
        width="240"
        height="240"
        viewBox="0 0 240 240"
        style={propsPhoto}
      >
        <SVGBody />
      </animated.svg>
    </div>
  );
};

export default AboutPortrait;
