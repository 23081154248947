/**
 * @name Work detail component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

// @ts-nocheck
import { h } from "preact";
import { memo } from "preact/compat";
import { useCallback, useEffect, useState } from "preact/hooks";
import { Redirect } from "react-router-dom";
import { animated, useSpring } from "react-spring";

import { work } from "@app/assets/data/main.json";
import WorkDetailBody from "@app/components/portfolio/routes/work-detail/body";
import { ISimpleRoute } from "@app/routes/simple-route";
import { delay, dispatcher, updateFavicon } from "@app/utils/common";

import * as style from "./style.scss";

//
const getData = (url: string) => {
  const intro = work.find((item: any) => item.url === url);

  if (intro === null) {
    return;
  }

  const index = work.indexOf(intro);
  if (index > -1) {
    const nextIndex = index + 1 > work.length - 1 ? 0 : index + 1;
    const nextIntro = work[nextIndex];

    return {
      intro,
      nextIndex,
      nextIntro
    };
  }

  return null;
};

export interface IWorkDetail {
  url: string;
  state: string;
}

interface IData {
  intro: any;
  content: [];
  nextIndex: number;
  nextIntro: any;
}

const WorkDetail: preact.FunctionalComponent<ISimpleRoute & IWorkDetail> = ({
  url,
  state: propState
}) => {
  const [state, setState] = useState(propState);
  const [{ intro, content, nextIndex, nextIntro }, setData] = useState({
    intro: null,
    content: [],
    nextIndex: -1,
    nextIntro: null
  });
  const [redirect, setRedirect] = useState(false);

  // Transition
  const props = useSpring({
    height: propState === "leave" ? 0 : window.innerHeight,
    delay: 250,
    immediate: state === "reset"
  });

  const urlChangeHandler = useCallback(() => {
    (async () => {
      if (content) {
        setState("leave");

        await delay(1000);

        setState("reset");
      }

      // @ts-ignore
      const data: IData = getData(url);

      if (data === null) {
        setRedirect(true);
        return;
      }

      const {
        bg_color1,
        bg_color2,
        camera_position,
        favicon,
        shape,
        shape_color
      } = data.intro;

      dispatcher.dispatch("color::change", {
        bg_color1,
        bg_color2,
        camera_position,
        delay: 1000,
        shape,
        shape_color
      });

      updateFavicon(favicon);

      fetch(data.intro.data_url)
        .then(response => response.json())
        .then(response => {
          setData({
            ...data,
            content: response || []
          });

          setState("enter");
        });
    })();
  }, [url]);

  useEffect(() => {
    urlChangeHandler();
  }, [url]);

  useEffect(() => {
    setState(propState);
  }, [propState]);

  if (redirect) {
    return <Redirect to="/404" />;
  }

  //
  if (!content || state === "reset" || nextIntro === null) {
    return <div />;
  }

  return (
    <animated.div className={style.section} style={props}>
      <WorkDetailBody
        state={state}
        intro={intro}
        content={content}
        nextIndex={nextIndex}
        nextIntro={nextIntro}
      />
    </animated.div>
  );
};

export default memo(WorkDetail);
