/**
 * @name Block - Desktop with support for video or image component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

import VideoPlayer from "@app/components/common/video-player";
import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockDesktop {
  placeholder?: string | null;
  screen_type: string;
  state: string;
  url: string;
}

const BlockDesktop: preact.FunctionalComponent<IBlockDesktop & IWaypoint> = ({
  container,
  placeholder,
  screen_type,
  state,
  url
}) => {
  const [play, setPlay] = useState(false);

  const [props, set] = useSpring(() => ({
    transform: "translate3d(0, 20vh, 0)",
    duration: 500
  }));

  const enterHandler = useCallback(() => {
    setPlay(true);
  }, []);

  const leaveHandler = useCallback(() => {
    setPlay(false);
  }, []);

  useEffect(() => {
    if (state === "enter") {
      set({ transform: "translate3d(0, 0, 0)", delay: 750 });
    }
  }, [state]);

  return (
    <Waypoint
      scrollableAncestor={container}
      onEnter={enterHandler}
      onLeave={leaveHandler}
      topOffset="25%"
      bottomOffset="25%"
    >
      <section className={style.section}>
        <animated.div className={`container ${style.container}`} style={props}>
          <div className="row">
            <div className={`col-12 ${style.screen}`}>
              <div className={style.player}>
                <div className={style.playerScreen}>
                  <div
                    className={`${style.playerBackground} ${
                      screen_type === "desktop" ? style.desktop : style.tablet
                    }`}
                  />
                  <div className={style.playerView}>
                    <VideoPlayer
                      placeholder={placeholder}
                      play={play}
                      url={url}
                    />
                  </div>
                </div>
                {screen_type === "desktop" && (
                  <div className={style.playerBase}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1390"
                      height="40"
                      viewBox="0 0 1390 40"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path
                          fill="#E5E5E5"
                          d="M641.217776,40 L747.782237,40 L1328.11289,40 C1354.93151,40 1378.54779,31.0489813 1387.99856,25 L1.00071994,25 C10.4522207,31.0489813 34.0685037,40 60.8871189,40 L641.217776,40 Z"
                        />
                        <path
                          fill="#F5F5F5"
                          d="M0.729804955,0 C0.352445967,0 0,0.30263233 0,0.692342831 L0,21.783104 C0,22.5610975 0.0915919873,23.5210986 1.02143384,24.1813224 C1.38487085,24.4461257 1.77908276,24.7216354 2.19527675,25 L1387.80399,25 C1388.35134,24.6352709 1388.84814,24.2812482 1389.30024,23.9372181 C1389.94431,23.4504368 1390,22.693856 1390,22.07503 L1390,0.713755496 C1390,0.324044995 1389.5948,0 1389.25334,0 L0.729804955,0 Z"
                        />
                      </g>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </animated.div>
      </section>
    </Waypoint>
  );
};

export default BlockDesktop;
