// @ts-nocheck
/**
 * @name Block - Text component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import LineText from "@app/components/common/transitions/line-text";
import AboutPotrait from "@app/components/portfolio/ui/about-portrait";

const ENTER_DELAY: number = 1000;

import style from "./style.scss";

export interface IBlockAboutIntro {
  description: string;
  state: string;
  title: string;
}

// @ts-nocheck
const BlockAboutIntro: preact.FunctionalComponent<IBlockAboutIntro> = ({
  description,
  state,
  title
}) => {
  const [show, set] = useState(false);

  useEffect(() => {
    set(true);
  }, []);

  useEffect(() => {
    if (state === "leave") {
      set(false);
    }
  }, [state]);

  return (
    <section className={style.section}>
      <div className="container">
        <div className={`row ${style.inner}`}>
          <div className={`col-md-6 col-xl-5 ${style.body}`}>
            <h2 className="line-text">
              <LineText
                delay={show ? ENTER_DELAY : 0}
                lines={title}
                show={show}
              />
            </h2>
            {description && (
              <p className="lead line-text">
                <LineText
                  delay={show ? ENTER_DELAY + 200 : 100}
                  lines={description}
                  show={show}
                />
              </p>
            )}
          </div>
          <div className="col-md-4">
            <AboutPotrait delay={show ? ENTER_DELAY + 500 : 200} show={show} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockAboutIntro;
