/**
 * @name UI - Background - Batch component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { getLayout } from "./utils";

// Object pool for performance
const objectPool = [1, 2, 3, 4, 5, 6].map(() => {
  // TODO: Fine tune layouts based upon screen
  return getLayout({});
});

export const fetchObject = () => {
  if (objectPool.length > 0) {
    return objectPool.pop();
  }
  return null;
};

export const releaseObject = (value: any) => {
  if (value) {
    objectPool.push(value);
  }
};

export const poolLength = () => {
  return objectPool.length;
};
