/**
 * @name Footer - Social link component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";

import * as Icons from "@app/components/portfolio/ui/icons";

export interface ISocialLink {
  icon: string;
  url: string;
}

const SocialLink: preact.FunctionalComponent<ISocialLink> = ({ icon, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="social-link"
    >
      {icon === "instagram" && <Icons.IconInstagram />}
      {icon === "linkedin" && <Icons.IconLinkedIn />}
      {icon === "gitlab" && <Icons.IconGitlab />}
      {icon === "dribbble" && <Icons.IconDribble />}
      {icon === "medium" && <Icons.IconMedium />}
    </a>
  );
};

export default SocialLink;
