// @ts-nocheck
/**
 * @name Block - Text component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockText {
  color: string;
  title: string;
  text1: string;
  text2: string;
}

// @ts-nocheck
const BlockText: preact.FunctionalComponent<IBlockText & IWaypoint> = ({
  color,
  container,
  title,
  text1,
  text2
}) => {
  const [enabled, setEnabled] = useState(true);

  const [propTitle, setTitle, stopTitle] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const [propText1, setText1, stopText1] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const [propText2, setText2, stopText2] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const enterHandler = useCallback(() => {
    setEnabled(false);

    if (title) {
      setTitle({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 250 });
    }

    if (text1) {
      setText1({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 350 });
    }

    if (text2) {
      setText2({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 450 });
    }
  }, []);

  useEffect(() => {
    return () => {
      stopTitle();
      stopText1();
      stopText2();
    };
  }, []);

  const BlockTextBody = useCallback(
    () => (
      <div className="container">
        {title && (
          <div className="row">
            <div className="col-12 col-md-6">
              <animated.h3
                style={{ color, ...propTitle }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          </div>
        )}
        {(text1 || text2) && (
          <div className="row">
            {text1 && (
              <animated.div
                className={`col-md-6 ${style.block}`}
                dangerouslySetInnerHTML={{ __html: text1 }}
                style={propText1}
              />
            )}
            {text2 && (
              <animated.div
                className={`col-md-6 ${style.block}`}
                dangerouslySetInnerHTML={{ __html: text2 }}
                style={propText2}
              />
            )}
          </div>
        )}
      </div>
    ),
    []
  );

  if (enabled) {
    return (
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        bottomOffset="40%"
      >
        <section className={style.section}>
          <BlockTextBody />
        </section>
      </Waypoint>
    );
  }

  return (
    <section className={style.section}>
      <BlockTextBody />
    </section>
  );
};

export default BlockText;
