/**
 * @name Utils - Favicon
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

const updateFavicon = (url: string) => {
  // @ts-ignore
  document.querySelector("link[rel='shortcut icon']").href = url;

  // @ts-ignore
  document.querySelector("link[rel*='icon']").href = url;
};

export default updateFavicon;
