/**
 * @name Work overview - Slide controls counter component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";
import { useEffect, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";

import { useInterval } from "@app/hooks/common";

import style from "./style.scss";

export interface ICounter {
  duration: number;
  onComplete: () => void;
  selected: number;
}

// @ts-nocheck
const Counter: preact.FunctionalComponent<ICounter> = ({
  duration,
  onComplete,
  selected
}) => {
  const [count, setCount] = useState(0);
  const [play, setPlay] = useState(false);
  const [props, setProps] = useSpring(() => ({ width: "0%" }));

  useInterval(
    () => {
      setCount(count + 100);

      const width: number = (count / duration) * 100;
      setProps({ width: `${width}%`, immediate: false });

      if (count > duration) {
        onComplete();
      }
    },
    play ? 100 : null
  );

  useEffect(() => {
    setPlay(true);

    return () => {
      setPlay(false);
    };
  }, []);

  useEffect(() => {
    if (!play) {
      return;
    }

    setProps({
      width: "0%",
      immediate: true
    });

    setCount(0);
  }, [selected]);

  return <animated.span className={style.value} style={props} />;
};

export default memo(Counter);
