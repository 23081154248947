/**
 * @name Block - Mobile with support for video or image component
 */

import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

import VideoPlayer from "@app/components/common/video-player";
import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockVideoMobile {
  url: string;
}

const BlockVideoMobile: preact.FunctionalComponent<IBlockVideoMobile &
  IWaypoint> = ({ container, url }) => {
  const [enabled, setEnabled] = useState(true);

  const [play, setPlay] = useState(false);

  const [props, setAnimate] = useSpring(() => ({
    transform: "translate3d(0, 150%, 0)"
  }));

  const enterHandler = useCallback(() => {
    if (enabled) {
      setEnabled(true);

      setAnimate({ transform: "translate3d(0, 0, 0)", delay: 250 });
    }

    setPlay(true);
  }, []);

  const leaveHandler = useCallback(() => {
    setPlay(false);
  }, []);

  const BlockMobileBody = useCallback(
    () => (
      <div className={style.player_screen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="500"
          height="980"
          viewBox="0 0 500 980"
        >
          <g fill="none" fill-rule="evenodd">
            <rect
              width="454"
              height="930"
              x="20"
              y="20"
              fill="#171717"
              rx="50"
            />
            <g fill="#D8D8D8" transform="translate(205 60)">
              <rect width="59.855" height="11.971" rx="5.986" />
              <circle cx="78" cy="6" r="6" />
            </g>
            <circle cx="247" cy="900" r="30" fill="#E5E5E5" />
          </g>
        </svg>
        <div className={style.player_view}>
          <VideoPlayer play={play} url={url} />
        </div>
      </div>
    ),
    [play, url]
  );

  if (enabled) {
    return (
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        onLeave={leaveHandler}
        topOffset="25%"
        bottomOffset="60%"
      >
        <div className={style.section}>
          <animated.div className={style.player} style={props}>
            <BlockMobileBody />
          </animated.div>
        </div>
      </Waypoint>
    );
  }

  return (
    <div className={style.section}>
      <animated.div className={style.player} style={props}>
        <BlockMobileBody />
      </animated.div>
    </div>
  );
};

export default BlockVideoMobile;
