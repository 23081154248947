/**
 * @name Route - Work detail
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";

import WorkDetail from "@app/components/portfolio/routes/work-detail";
import { IWorkDetail } from "@app/components/portfolio/routes/work-detail";
import { ISimpleRoute } from "@app/routes/simple-route";

const Route: preact.FunctionalComponent<ISimpleRoute & IWorkDetail> = ({
  state,
  url = ""
}) => <WorkDetail state={state} url={url} />;

export default Route;
