/**
 * @name UI - Background - Skybox component with vertext & fragment shader
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

// Taken from https://stackoverflow.com/questions/45092071/implementing-a-gradient-shader-in-three-js

import {
  BackSide,
  BoxGeometry,
  Color,
  Group,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  ShaderMaterial
} from "three";

import { CAMERA_FAR_RANGE } from "@app/components/portfolio/ui/background/webgl-background/stage";

// Default uniforms
const uniforms = {
  color1: {
    type: "c",
    value: new Color(0x000000)
  },
  color2: {
    type: "c",
    value: new Color(0xffffff)
  }
};

// @ts-ignore
const fragmentShader = document.getElementById("fragmentShader").text;

// @ts-ignore
const vertexShader = document.getElementById("vertexShader").text;

// New group
const group = new Group();

// Create material
const materialCube = new ShaderMaterial({
  uniforms,
  vertexShader,
  fragmentShader,
  lights: false
});
materialCube.side = BackSide;

const geometryCube = new BoxGeometry(
  CAMERA_FAR_RANGE,
  CAMERA_FAR_RANGE,
  CAMERA_FAR_RANGE
);

// Delete top
delete geometryCube.faces[4];
delete geometryCube.faces[5];

// Delete bottom
delete geometryCube.faces[6];
delete geometryCube.faces[7];

// Fix faces
geometryCube.faces = geometryCube.faces.filter((a: any) => a !== undefined);

const cube = new Mesh(geometryCube, materialCube);
group.add(cube);

const geometryPlane = new PlaneGeometry(CAMERA_FAR_RANGE, CAMERA_FAR_RANGE, 1);
const material1Plane = new MeshBasicMaterial({
  color: 0xffff00
});

const material2Plane = new MeshBasicMaterial({
  color: 0xffff00
});

const planeTop = new Mesh(geometryPlane, material1Plane);
planeTop.rotation.x = (90 * Math.PI) / 180;
planeTop.position.y = CAMERA_FAR_RANGE * 0.5;
group.add(planeTop);

const planeBottom = new Mesh(geometryPlane, material2Plane);
planeBottom.rotation.x = (-90 * Math.PI) / 180;
planeBottom.position.y = CAMERA_FAR_RANGE * -0.5;
group.add(planeBottom);

export const updateColor = (color1: any, color2: any) => {
  const c1 = new Color(color1);
  const c2 = new Color(color2);

  cube.material.uniforms.color1.value = c2;
  cube.material.uniforms.color2.value = c1;

  planeTop.material.color = c1;
  planeBottom.material.color = c2;
};

export default group;
