/**
 * @name Common - Video player
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useEffect, useRef } from "preact/hooks";
import { useSpring } from "react-spring";

import { isMobile } from "@app/utils/common/Screen";

import style from "./style.scss";

export interface IVideoPlayer {
  placeholder?: string | null;
  play: boolean;
  url: string;
}

const VideoPlayer: preact.FunctionalComponent<IVideoPlayer> = ({
  play = false,
  placeholder,
  url
}) => {
  const $ref = useRef(null);
  const showVideo: boolean = !isMobile() || placeholder === undefined;

  const [props, set] = useSpring(() => ({
    opacity: 0
  }));

  const playHandler = useCallback(() => {
    set({ opacity: 1, delay: 250 });
  }, []);

  const pauseHandler = useCallback(() => {
    set({ opacity: 0 });
  }, []);

  useEffect(() => {
    if (showVideo) {
      const video: any = $ref.current;

      if (video) {
        if (play) {
          video.currentTime = 0;

          // Taken from developers.google.com/web/updates/2017/06/play-request-was-interrupted
          const promise: any = video.play();
          if (promise !== undefined) {
            promise.then(() => {}).catch((error: any) => {});
          }
        } else {
          video.pause();
        }
      }
    }
  }, [play, url]);

  // @ts-ignore
  if (showVideo) {
    return (
      <video
        autoPlay={false}
        className={style.video}
        height="240"
        loop={true}
        muted={true}
        onPause={pauseHandler}
        onPlay={playHandler}
        playsInline={true}
        preload="metadata"
        ref={$ref}
        style={props}
        width="320"
      >
        <source src={url} type="video/mp4" />
      </video>
    );
  }

  if (placeholder) {
    return <img src={placeholder} className={style.image} alt="" />;
  }

  return null;
};

export default VideoPlayer;
