/**
 * @name About Me component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";
import { useCallback, useEffect, useRef } from "preact/hooks";
import { animated, useSpring } from "react-spring";

import { about } from "@app/assets/data/main.json";
import {
  BlockAboutIntro,
  BlockAwards,
  BlockText
} from "@app/components/portfolio/blocks";
import { ISimpleRoute } from "@app/routes/simple-route";
import { dispatcher, updateFavicon } from "@app/utils/common";

const {
  bg_color1,
  bg_color2,
  camera_position,
  content,
  favicon,
  shape,
  shape_color
} = about;

import * as style from "./style.scss";

export interface IAboutMe {}

const AboutMe: preact.FunctionalComponent<ISimpleRoute & IAboutMe> = ({
  state
}) => {
  const $ref = useRef(null);

  const props = useSpring({
    config: { duration: 400 },
    height: state === "leave" ? 0 : window.innerHeight
  });

  const propsText = useSpring({
    from: {
      transform: "translate3d(0, 20vh, 0)"
    },
    to: {
      transform:
        state === "enter" || state === "update"
          ? "translate3d(0, 0, 0)"
          : "translate3d(0, 0, 0)"
    },
    delay: state === "enter" ? 1500 : 0
  });

  const renderBlock = useCallback(
    (block: any) => {
      if (block === null || block.type === null) {
        return null;
      }

      switch (block.type) {
        case "about_intro":
          return <BlockAboutIntro state={state} {...block} />;
        case "text":
          return (
            <animated.div style={propsText}>
              <BlockText state={state} {...block} />
            </animated.div>
          );
        case "awards":
          return <BlockAwards state={state} {...block} />;
      }
    },
    [state]
  );

  useEffect(() => {
    dispatcher.dispatch("color::change", {
      bg_color1,
      bg_color2,
      camera_position,
      delay: 1000,
      shape,
      shape_color
    });

    updateFavicon(favicon);
  }, []);

  return (
    <animated.div className={style.section} style={props}>
      <div className={style.inner} ref={$ref}>
        {content && content.map(block => renderBlock(block))}
      </div>
    </animated.div>
  );
};

export default memo(AboutMe);
