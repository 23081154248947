/**
 * @name Header - Container component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";

import { ButtonHamburger } from "@app/components/portfolio/buttons";
import HeaderLink from "@app/components/portfolio/header/header-link";
import HeaderLogo from "@app/components/portfolio/header/header-logo";
import { DESKTOP_LG_WIDTH } from "@app/utils/common/Screen";

export interface IHeaderContainer {
  onMenuOverlayOpen: any;
  show: boolean;
  width?: number;
}

const HeaderContainer: preact.FunctionalComponent<IHeaderContainer> = ({
  onMenuOverlayOpen,
  show,
  width
}) => {
  return (
    <header className="header">
      <nav className="header__nav">
        <div className="container header__nav-container">
          <HeaderLogo delay={400} show={true} href="/" label="Home" />
          {width && width >= DESKTOP_LG_WIDTH && (
            <HeaderLink
              show={show}
              delay={600}
              type="link"
              href="/"
              label="Featured work"
            />
          )}
          {width && width >= DESKTOP_LG_WIDTH && (
            <HeaderLink
              show={show}
              type="link"
              delay={800}
              href="/about-me/"
              label="About me"
            />
          )}
          {width && width >= DESKTOP_LG_WIDTH && (
            <HeaderLink
              show={show}
              type="external"
              delay={1000}
              href="https://www.linkedin.com/in/heytopher"
              label="Get in touch"
              subtext="via LinkedIn"
            />
          )}
          {width && width < DESKTOP_LG_WIDTH && (
            <ButtonHamburger show={show} onClick={onMenuOverlayOpen} />
          )}
        </div>
      </nav>
    </header>
  );
};

export default HeaderContainer;
