/**
 * @name UI - Background - Stage component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { PerspectiveCamera, Scene, WebGLRenderer } from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

interface IInitialize {
  height: number;
  htmlElement: HTMLElement;
  onInitialize: () => void;
  width: number;
}

let camera: any = null;
let hasInitialized: boolean = false;
let renderer: any = null;
let scene: any = null;

export const CAMERA_FAR_RANGE = 6000;

const Stage = () => {
  const initialize = ({
    height,
    htmlElement,
    onInitialize,
    width
  }: IInitialize) => {
    // Retina - window.devicePixelRatio ? window.devicePixelRatio : 1

    if (hasInitialized || (!hasInitialized && htmlElement === null)) {
      return;
    }

    hasInitialized = true;

    // Setup renderer
    renderer = new WebGLRenderer({ antialias: false, alpha: false });
    renderer.setPixelRatio(1);
    renderer.setSize(width, height);
    renderer.autoClear = false;
    renderer.setClearColor(0x000000, 0);
    htmlElement.appendChild(renderer.domElement);

    // Setup scene
    scene = new Scene();

    // Setup camera
    camera = new PerspectiveCamera(40, width / height, 1, CAMERA_FAR_RANGE);
    scene.add(camera);

    // const controls = new OrbitControls(camera, renderer.domElement);

    // Save resize handler locally
    if (onInitialize) {
      onInitialize();
    }
  };

  const resize = (width: number, height: number) => {
    if (!hasInitialized && camera === null) {
      return;
    }

    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  };

  const render = () => {
    renderer.clear();
    renderer.render(scene, camera);
  };

  return {
    // Properties
    getCamera: () => camera,
    getRenderer: () => renderer,
    getScene: () => scene,
    //
    // Methods
    initialize,
    render,
    resize
  };
};

const stage = Stage();

export default stage;
