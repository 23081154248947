/**
 * @name Header component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { Fragment, h } from "preact";
import { memo } from "preact/compat";
import { useCallback, useEffect, useState } from "preact/hooks";

import HeaderContainer from "@app/components/portfolio/header/header-container";
import MenuOverlay from "@app/components/portfolio/header/menu-overlay";
import useWindowSize from "@app/hooks/common/useWindowSize";
import { DESKTOP_LG_WIDTH } from "@app/utils/common/Screen";

export interface IHeader {
  show: boolean;
  url: string;
}

const Header: preact.FunctionalComponent<IHeader> = ({ show = false, url }) => {
  const { width } = useWindowSize();

  // State: Mobile menu open
  const [menuOpen, setMenuOpen] = useState(false);

  // Event handler:
  const hamburgerClickHandler = useCallback(() => {
    setMenuOpen(true);
  }, [menuOpen]);

  // Event handler:
  const closeMenuHandler = useCallback(() => {
    setMenuOpen(false);
  }, [menuOpen]);

  // Lifecycle hook: Close menu if show or URL change
  useEffect(() => {
    setMenuOpen(false);
  }, [show, url]);

  return (
    <Fragment>
      <HeaderContainer
        onMenuOverlayOpen={hamburgerClickHandler}
        show={show}
        width={width}
      />
      {width && width < DESKTOP_LG_WIDTH && (
        <MenuOverlay onClose={closeMenuHandler} show={menuOpen} />
      )}
    </Fragment>
  );
};

export default memo(Header);
