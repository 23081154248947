/**
 * @name UI - Background component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import Cookie from "cookies-js";
import { h } from "preact";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";

import SimpleBackground from "@app/components/portfolio/ui/background/simple-background";
import WebGLBackground from "@app/components/portfolio/ui/background/webgl-background";
import { delay, dispatcher } from "@app/utils/common";
import { hasWebGL } from "@app/utils/common/Screen";

import style from "./style.scss";

import { work } from "@app/assets/data/main.json";

export interface IBackground {
  bg_color1: string;
  bg_color2: string;
  camera_position: string;
  htmlElement?: HTMLElement | unknown;
  shape: string;
  shape_color: string;
  update: boolean;
}

//
const IS_NEW_VISITOR: boolean = Cookie.get("showintro") === undefined;
const defaultState = IS_NEW_VISITOR
  ? {
      bg_color1: "#264C8C",
      bg_color2: "#00173B",
      camera_position: "camera_2",
      shape: "cube",
      shape_color: "#1B3D75",
      update: true
    }
  : {
      bg_color1: work[0].bg_color1,
      bg_color2: work[0].bg_color2,
      camera_position: work[0].camera_position,
      shape: work[0].shape,
      shape_color: work[0].shape_color,
      update: true
    };

const Background: preact.FunctionalComponent = () => {
  const $ref = useRef(null);

  const [
    { bg_color1, bg_color2, camera_position, shape, shape_color, update },
    set
  ] = useState(defaultState);

  const colorChangeHandler = useCallback(async (props: any) => {
    await delay(props.delay);

    set({
      bg_color1: props.bg_color1,
      bg_color2: props.bg_color2,
      camera_position: props.camera_position,
      shape: props.shape,
      shape_color: props.shape_color,
      update: true
    });
  }, []);

  useEffect(() => {
    dispatcher.addListener("color::change", colorChangeHandler);

    return () => {
      dispatcher.removeListener("color::change", colorChangeHandler);
    };
  }, []);

  return (
    <div className={style.background} ref={$ref}>
      {hasWebGL && $ref.current ? (
        <WebGLBackground
          htmlElement={($ref.current as unknown) as HTMLElement}
          bg_color1={bg_color1}
          bg_color2={bg_color2}
          camera_position={camera_position}
          shape_color={shape_color}
          shape={shape}
          update={update}
        />
      ) : (
        <SimpleBackground
          bg_color1={bg_color1}
          bg_color2={bg_color2}
          camera_position={camera_position}
          shape_color={shape_color}
          shape={shape}
          update={update}
        />
      )}
    </div>
  );
};

export default Background;
