/**
 * @name Utils - delay
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

// @ts-ignore
const delay: Promise = (ms: number) => new Promise(r => setTimeout(r, ms));

export default delay;
