/**
 * @name Route - Work overview component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";
import { useCallback, useEffect, useState } from "preact/hooks";

import { work } from "@app/assets/data/main.json";
import ViewSlider from "@app/components/common/view-slider";
import Slide from "@app/components/portfolio/routes/work-overview/slide";
import SlideControls from "@app/components/portfolio/routes/work-overview/slide-controls";
import AvailabilityNotification from "@app/components/portfolio/ui/availability-notification";
import { ISimpleRoute } from "@app/routes/simple-route";
import { delay, dispatcher, updateFavicon } from "@app/utils/common";
import { isPointerCoarse } from "@app/utils/common/Screen";

import * as style from "./style.scss";

const WorkOverview: preact.FunctionalComponent<ISimpleRoute> = ({ state }) => {
  const [show, setShow] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);

  const isTouch = isPointerCoarse();

  const [{ selected, autoPlay, bgDelay }, setState] = useState({
    autoPlay: true,
    bgDelay: 1000,
    selected: 0
  });

  const dragStartHandler = useCallback(() => {
    setState({
      autoPlay: false,
      bgDelay: 0,
      selected
    });
  }, [selected]);

  const slideChangeHandler = useCallback(
    (slide: number, triggeredByUser: boolean = false) => {
      setState({
        bgDelay: isTouch ? 0 : 1000,
        autoPlay: autoPlay && !triggeredByUser,
        selected: slide
      });
    },
    [selected]
  );

  /* 
  // Event handler: Update background with lerp
  // TODO: Add Lerp background on slide in future
  const slideUpdateHandler = useCallback((x: number) => {
    if (x) {
      return;
    }
  }, []);
  */

  // Event handler: Control update
  const controlUpdateHandler = useCallback(
    (slide: number, playNext: boolean) => {
      setState({ autoPlay: playNext, bgDelay: 1000, selected: slide });
    },
    [selected]
  );

  // @ts-ignore
  useEffect(() => {
    const {
      bg_color1,
      bg_color2,
      camera_position,
      favicon,
      shape,
      shape_color
    } = work[selected];

    dispatcher.dispatch("color::change", {
      bg_color1,
      bg_color2,
      camera_position,
      delay: bgDelay,
      shape,
      shape_color
    });

    updateFavicon(favicon);
  }, [selected]);

  useEffect(() => {
    (async () => {
      await delay(isTouch ? 750 : 150);
      setShow(true);
      await delay(2000);
      setShowControls(true);
      await delay(500);
      setShowAvailability(true);
    })();
  }, []);

  useEffect(() => {
    if (state === "leave") {
      setShowControls(false);
      setShowAvailability(false);
    }
  }, [state]);

  if (!show) {
    return <section className={style.section} />;
  }

  return (
    <section className={style.section}>
      <ViewSlider
        onDragStart={dragStartHandler}
        onSlideChange={slideChangeHandler}
        selected={selected}
      >
        {work.map((item: any, index: number) => (
          <Slide
            key={index}
            enableLeftArrow={index !== 0}
            enableRightArrow={index !== work.length - 1}
            state={index === selected ? state : "leave"}
            {...item}
            current={index + 1}
            total={work.length}
          />
        ))}
      </ViewSlider>
      <AvailabilityNotification show={showAvailability} />
      <SlideControls
        autoPlay={autoPlay}
        onControlUpdate={controlUpdateHandler}
        selected={selected}
        show={showControls}
        total={work.length}
      />
    </section>
  );
};

export default memo(WorkOverview);
