/**
 * @name Block - Technology component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockTechnology {
  awards?: [];
  color: string;
  min_margin?: boolean;
  technology: [];
}

const BlockTechnology: preact.FunctionalComponent<IBlockTechnology &
  IWaypoint> = ({
  awards,
  color,
  container,
  min_margin = false,
  technology
}) => {
  const [enabled, setEnabled] = useState(true);

  const [propsTitle, setTitle] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const [propsText, setText] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const enterHandler = useCallback(() => {
    setEnabled(false);

    setTitle({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 150 });
    setText({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 300 });
  }, []);

  const BlockTechnologyBody = useCallback(
    () => (
      <div className="container">
        <div className="row">
          {technology && technology.length > 0 && (
            <div className={`col-md-8 ${style.technology}`}>
              <animated.h3 style={{ color, ...propsTitle }}>
                Built with
              </animated.h3>
              <animated.ul className="list" style={propsText}>
                {technology.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </animated.ul>
            </div>
          )}
          {awards && awards.length > 0 && (
            <div className={`col-md-8 ${style.awards}`}>
              <animated.h3 style={{ color, ...propsTitle }}>Awards</animated.h3>
              <animated.ul className="list" style={propsText}>
                {awards.map((item, index) => {
                  const { title, date, organisation, project, url } = item;
                  return (
                    <animated.li key={index}>
                      <p>
                        <a href={url} target="_blank" rel="noopener">
                          {title}
                          <br />
                          {project}
                        </a>
                        <br />
                        {date}, {organisation}
                      </p>
                    </animated.li>
                  );
                })}
              </animated.ul>
            </div>
          )}
        </div>
      </div>
    ),
    []
  );

  if (enabled) {
    return (
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        bottomOffset="40%"
      >
        <section
          className={
            min_margin
              ? `${style.section} ${style.min_margin}`
              : `${style.section}`
          }
        >
          <BlockTechnologyBody />
        </section>
      </Waypoint>
    );
  }

  return (
    <section
      className={
        min_margin ? `${style.section} ${style.min_margin}` : `${style.section}`
      }
    >
      <BlockTechnologyBody />
    </section>
  );
};

export default BlockTechnology;
