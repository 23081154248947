/**
 * @name Common - View slider component
 *
 * @version 1.0.0
 * @author Christopher Martin
 *
 * Adapted from https://codesandbox.io/s/54q5x6p92x
 *
 */

// TODO: Look into swipe - https://codesandbox.io/s/crimson-dawn-pzf9t

// @ts-nocheck
import clamp from "lodash-es/clamp";
import { h } from "preact";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import { animated, useSprings } from "react-spring";
import { useGesture } from "react-with-gesture";

import { useWindowSize } from "@app/hooks/common";
import { isPointerCoarse } from "@app/utils/common/Screen";

import * as style from "./style.scss";

export interface IViewSlider {
  children?: ReactChildren;
  selected: number;
  onDragStart: () => void;
  onSlideChange?: (slide: number) => void;
  onSlideUpdate?: (x: number) => void;
}

const ViewSlider: preact.FunctionalComponent<IViewSlider> = ({
  children,
  onDragStart,
  onSlideChange,
  onSlideUpdate,
  selected
}) => {
  const [dragStart, setDragStart] = useState(false);

  //
  // State: Window width and half width
  const { width } = useWindowSize();
  const halfWidth: number = width * 0.5;

  //
  // State: Persistent index which can be overwritten by 'selected' prop
  const index = useRef(selected);

  const snapped = useRef(selected);

  //
  // State: Flags for touch and mouse support
  // TODO: Make this a hook
  const hasTouch: boolean = isPointerCoarse();
  // const hasMouse: boolean = isPointerFine();

  //
  // Animation: Set position of each slide
  const [props, set] = useSprings(children.length, i => ({
    x: i * width,
    display: "block"
  }));

  // Event Handler: Reposition grid
  const resizeHandler = (
    immediate: boolean = false,
    down: boolean = false,
    xDelta: number = 0
  ) => {
    // For each slide...
    set(i => {
      //
      // Fix from demo: Hide slide if not within view
      if (i < index.current - 1 || i > index.current + 1) {
        return { display: "none" };
      }

      // Calculate x position
      const x = hasTouch
        ? (i - index.current) * width + (down ? xDelta : 0)
        : 0;

      // Call onSlide update if set
      if (index.current === i && onSlideUpdate) {
        onSlideUpdate(x);
      }

      return {
        display: "block",
        immediate,
        x
      };
    });
  };

  // Event handler: Add support for cursor to navigate forward and back
  const onClick = useCallback((e: MouseEvent) => {
    if (e.target.classList.contains("js-hit-left") && index.current - 1 > -1) {
      onSlideChange(index.current - 1, true);
    }
    if (
      e.target.classList.contains("js-hit-right") &&
      index.current + 1 < children.length
    ) {
      onSlideChange(index.current + 1, true);
    }
  }, []);

  // Hook: Scroll gesture
  // TODO: Known issue - going from desktop > responsive mode causes runtime error
  // possible solution - reload entire component
  const bind = useGesture({
    onAction: ({
      cancel,
      down,
      delta: [xDelta],
      direction: [xDir],
      distance,
      first
    }) => {
      // Do nothing if touch
      if (!hasTouch) {
        return;
      }

      // Trigger drag down
      setDragStart(first);

      // Cancel scrolling when 50% away from center (in either direction)
      if (down && distance > halfWidth) {
        index.current = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          children.length - 1
        );

        onSlideChange(index.current, true);

        snapped.current = true;

        cancel(index.current);
      }

      resizeHandler(false, down, xDelta);
    },
    onUp: ({ distance, direction: [xDir], delta: [xDelta] }) => {
      if (!hasTouch) {
        return;
      }

      // If snapped to position; do nothing
      if (snapped.current) {
        snapped.current = false;
        return;
      }

      snapped.current = false;

      // tslint:disable-next-line:early-exit
      if (distance > 150) {
        const next = clamp(
          index.current + (xDir > 0 ? -1 : 1),
          0,
          children.length - 1
        );

        index.current = next;
        onSlideChange(index.current, true);
      }

      resizeHandler(false, false, xDelta);
    }
  });

  // Lifecycle hook: On window resizeHandler -> reposition slide
  useEffect(() => {
    resizeHandler(true);
  }, [width]);

  // Lifecycle hook: Update view when selected value changes
  useEffect(() => {
    index.current = selected;
    onSlideChange(selected, false);
    resizeHandler(false);
  }, [selected]);

  useEffect(() => {
    if (dragStart && onDragStart) {
      onDragStart();
    }
  }, [dragStart]);

  //
  return (
    <div className={style.container} onClick={onClick}>
      {props.map(({ x, display }, i) => (
        <animated.div
          {...bind()}
          key={i}
          className={
            index.current === i
              ? `${style.slide} ${style.active}`
              : `${style.slide}`
          }
          style={{
            display,
            transform: x.interpolate(value => `translate3d(${value}px,0,0)`)
          }}
        >
          {children[i]}
        </animated.div>
      ))}
    </div>
  );
};

export default ViewSlider;
