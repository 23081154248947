/*
 * @name Button - Close component
 */

import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";

import style from "./style.scss";

export interface IButtonClose {
  delay?: number;
  color: string;
  onClick?: any;
  show?: boolean;
  url?: string;
}

const CloseSVG: preact.FunctionalComponent<IButtonClose> = ({
  color,
  delay,
  show
}) => {
  const props = useSpring({
    from: { opacity: 0, transform: "rotate(-135deg)" },
    to: {
      opacity: show ? 1 : 0,
      transform: show ? "rotate(0deg)" : "rotate(135deg)"
    },
    delay
  });

  return (
    <animated.span style={props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <g
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          transform="translate(1 1)"
        >
          <line x2="30" y2="30" />
          <line x2="30" y1="30" />
        </g>
      </svg>
    </animated.span>
  );
};

const ButtonClose: preact.FunctionalComponent<IButtonClose> = ({
  delay = 0,
  color = "#666",
  onClick,
  show = false,
  url
}) => {
  const clickHandler = useCallback(() => {
    if (onClick && show) {
      onClick();
    }
  }, []);

  if (url) {
    return (
      <Link to="/" className={style.button}>
        <CloseSVG color={color} show={show} delay={delay} />
      </Link>
    );
  }

  return (
    <button className={style.button} onClick={clickHandler}>
      <CloseSVG color={color} show={show} delay={delay} />
    </button>
  );
};

export default ButtonClose;
