/**
 * @name Header - Menu overlay component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

// TOOD: Fix TS error
// @ts-nocheck
import { h } from "preact";
import { memo } from "preact/compat";
import { Transition } from "react-spring/renderprops";

import MenuContainer from "@app/components/portfolio/header/menu-container";

export interface IMenuOverlay {
  onClose: any;
  show: boolean;
}

const MenuOverlay: preact.FunctionalComponent<IMenuOverlay> = ({
  onClose,
  show
}) => {
  // TODO: Make this a hook
  const config: any = {
    duration: 1000
  };

  return (
    <Transition
      items={show}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      config={config}
    >
      {value =>
        value && (() => <MenuContainer onClose={onClose} show={show} />)
      }
    </Transition>
  );
};

export default memo(MenuOverlay);
