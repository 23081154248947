/**
 * @name Block - Awards component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { Fragment, h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { animated, useSpring, useSprings } from "react-spring";
import { Waypoint } from "react-waypoint";

import { IWaypoint } from "@app/components/common/waypoint";
import Footer from "@app/components/portfolio/footer";

import style from "./style.scss";

export interface IBlockAwards {
  color: string;
  theme: string;
  state: string;
  items: [];
}

// @ts-nocheck
const BlockAwards: preact.FunctionalComponent<IBlockAwards & IWaypoint> = ({
  color,
  container,
  items,
  state,
  theme
}) => {
  const [showFooter, setShowFooter] = useState(false);

  const [propTitle, setTitle] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const [propItems, setItems] = useSprings(items.length, () => ({
    opacity: 0,
    transform: "translate3d(0, 25px, 0)"
  }));

  const enterHandler = useCallback(() => {
    setTitle({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 150 });

    setItems({
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
      delay: 300
    });

    setShowFooter(true);
  }, []);

  const leaveHandler = useCallback(() => {
    setShowFooter(false);
  }, []);

  const BlockAwardBody = useCallback(
    () => (
      <div className="container">
        <div className="row">
          {propItems && propItems.length > 0 && (
            <div className="col-sm-12">
              <animated.h3 style={{ color, ...propTitle }}>Awards</animated.h3>
              <ul className={`list ${propItems.length > 3 && "list-wrap"}`}>
                {propItems.map((props, index) => {
                  const { title, date, organisation, project, url } = items[
                    index
                  ];

                  return (
                    <animated.li key={index} style={props}>
                      <p>
                        <a href={url} target="_blank" rel="noopener">
                          {title}
                          <br />
                          {project}
                        </a>
                        <br />
                        {date}, {organisation}
                      </p>
                    </animated.li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    ),
    []
  );

  return (
    <Fragment>
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        onLeave={leaveHandler}
        bottomOffset="50%"
      >
        <section
          className={`${style.section} ${
            theme === "clear" ? style.clear : style.white
          }`}
        >
          <BlockAwardBody />
          <Footer
            show={showFooter && (state === "enter" || state === "update")}
            delay={10}
          />
        </section>
      </Waypoint>
    </Fragment>
  );
};

export default BlockAwards;
