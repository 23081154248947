/**
 * @name UI - Startup tip component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import LineText from "@app/components/common/transitions/line-text";
import { delay } from "@app/utils/common";

import style from "./style.scss";

const text1 = ["", "", "My friends call me Topher."];
const text2 = [
  "I'm an Australian freelance",
  "front-end & mobile app developer",
  "based in Amsterdam."
];
const text3 = [
  "I build websites,",
  "mobile apps and all sorts",
  "of clever things."
];

export interface IStartupTip {
  onExit: any;
}

const StartupTip: preact.FunctionalComponent<IStartupTip> = ({ onExit }) => {
  const [showText1, setShowText1] = useState(false);
  const [showText2, setShowText2] = useState(false);
  const [showText3, setShowText3] = useState(false);

  useEffect(() => {
    (async () => {
      await delay(500);
      setShowText1(true);
      await delay(1500);
      setShowText1(false);
      await delay(800);
      setShowText2(true);
      await delay(3000);
      setShowText2(false);
      await delay(1000);
      setShowText3(true);
      await delay(2500);
      setShowText3(false);
      await delay(1000);
      onExit();
    })();
  }, []);

  return (
    <section className={style.section}>
      <div className={style.background} />
      <div className="container full-height">
        <div className="row full-height">
          <div className="col-md-8 flex-end">
            <div className={style.body}>
              <h3 className="line-text">
                <LineText delay={0} lines={text1} show={showText1} />
              </h3>
              <h3 className="line-text">
                <LineText delay={0} lines={text2} show={showText2} />
              </h3>
              <h3 className="line-text">
                <LineText delay={0} lines={text3} show={showText3} />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartupTip;
