/**
 * @name Menu - Container component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";
import { Link } from "react-router-dom";
import { animated, useSpring, useTrail } from "react-spring";

import { ButtonClose } from "@app/components/portfolio/buttons";
import SocialLink from "@app/components/portfolio/footer/social-link";

import { footer, header } from "@app/assets/data/main.json";

import * as style from "./style.scss";

export interface IMenuContainer {
  onClose: any;
  show?: boolean;
}

const MenuContainer: preact.FunctionalComponent<IMenuContainer> = ({
  onClose,
  show
}) => {
  const { links: headerLinks } = header;
  const { links: footerLinks, kvk, legal } = footer;

  // Hook: Animation - background
  const propsBackground: any = useSpring({
    from: {
      transform: "translate3d(0, 100%, 0)"
    },
    to: {
      transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 100%, 0)"
    },
    delay: show ? 0 : 600
  });

  // Hook: Animation - buttons
  const propsButtons = useTrail(headerLinks.length, {
    opacity: show ? 1 : 0,
    from: { opacity: 0 },
    delay: show ? 400 : 0
  });

  // Hook: Animation - footer
  const propsFooter: any = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(0, 25px, 0)"
    },
    to: {
      opacity: show ? 1 : 0,
      transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 25px, 0)"
    },
    delay: show ? 500 : 0
  });

  return (
    <nav className={style.overlay}>
      <animated.span className={style.background} style={propsBackground} />
      <ButtonClose
        color="#fff"
        show={show}
        delay={show ? 400 : 0}
        onClick={onClose}
      />

      <div className={style.body}>
        <ul className="list">
          {propsButtons.map(({ opacity }, index) => {
            const item = headerLinks[index];

            return (
              <animated.li key={index}>
                {item.type === "link" && (
                  <Link to={item.url} onClick={onClose}>
                    <animated.span className={style.label} style={{ opacity }}>
                      {item.title}
                    </animated.span>
                  </Link>
                )}
                {item.type === "external" && (
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <animated.span className={style.label} style={{ opacity }}>
                      {item.title}
                    </animated.span>
                  </a>
                )}
              </animated.li>
            );
          })}
        </ul>
      </div>

      <animated.div className={style.footer} style={propsFooter}>
        <ul className="list">
          {footerLinks.map((item, index) => (
            <li key={index}>
              <SocialLink {...item} />
            </li>
          ))}
        </ul>
        <p>
          <strong>&copy; {legal}</strong>&nbsp; All rights reserved.&nbsp;
          {kvk}
        </p>
      </animated.div>
    </nav>
  );
};

export default memo(MenuContainer);
