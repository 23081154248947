/**
 * @name Block - Detail intro component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h, Fragment } from "preact";
import { animated, useSpring } from "react-spring";

import LineText from "@app/components/common/transitions/line-text";
import { ButtonPrimary } from "@app/components/portfolio/buttons";

import style from "./style.scss";

const ENTER_DELAY: number = 500;

export interface IBlockIntro {
  agency: any;
  client: string;
  delay: number;
  project_url: string;
  show: boolean;
  title_html: [];
  year: number;
}

const BlockIntro: preact.FunctionalComponent<IBlockIntro> = ({
  agency,
  client,
  delay,
  project_url,
  show,
  title_html,
  year
}) => {
  const props1 = useSpring({
    from: {
      transform: "translate3d(0, 100%, 0)"
    },
    to: {
      transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 100%, 0)"
    },
    delay: show ? ENTER_DELAY + delay + 100 : delay + 50
  });

  const props2 = useSpring({
    from: {
      transform: "translate3d(0, 100%, 0)"
    },
    to: {
      transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 100%, 0)"
    },
    delay: show ? ENTER_DELAY + delay + 200 : delay + 100
  });

  const props3 = useSpring({
    from: {
      transform: "translate3d(0, 100%, 0)"
    },
    to: {
      transform: show ? "translate3d(0, 0, 0)" : "translate3d(0, 100%, 0)"
    },
    delay: show ? ENTER_DELAY + delay + 300 : delay + 150
  });

  return (
    <section className={style.section}>
      <div className="container fullheight">
        <div className="row fullheight">
          <div className={`col-12 ${style.inner}`}>
            <div className={style.body}>
              <h2 className="line-text">
                <LineText
                  delay={show ? ENTER_DELAY + delay : delay}
                  lines={title_html}
                  show={show}
                />
              </h2>
              <ul className="list">
                <li className="line-text">
                  <span>
                    <animated.span style={props1}>
                      <b>Client</b>
                    </animated.span>
                  </span>
                  <span>
                    <animated.span style={props1}>{client}</animated.span>
                  </span>
                </li>
                <li className="line-text">
                  <span>
                    <animated.span style={props2}>
                      <b>Agency </b>
                    </animated.span>
                  </span>
                  <span>
                    <animated.span style={props2}>
                      {agency.map((item: any, index: number) => {
                        return (
                          <Fragment>
                            <a
                              key={index}
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.label}
                            </a>
                            {index !== agency.length - 1 ? " + " : ""}
                          </Fragment>
                        );
                      })}
                    </animated.span>
                  </span>
                </li>
                <li className="line-text">
                  <span>
                    <animated.span style={props3}>
                      <b>Year </b>
                    </animated.span>
                  </span>
                  <span>
                    <animated.span style={props3}>{year}</animated.span>
                  </span>
                </li>
              </ul>

              {project_url && (
                <ButtonPrimary
                  delay={show ? ENTER_DELAY + delay + 300 : delay + 200}
                  show={show}
                  url={project_url}
                  target="_blank"
                  label="Visit website"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockIntro;
