/**
 * @name Hooks - Interval
 *
 * Taken from https://codesandbox.io/s/n9lx3rllxp and rewritten in TypeScript
 */

import { useEffect, useRef } from "preact/hooks";

const useInterval = (
  callback: () => void,
  delay: number | unknown = null,
  startImmediate: boolean = false
): void => {
  // TODO: This should be strictly typed
  const savedCallback: any = useRef<void | unknown>(null);

  // remember latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // // setup the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay as number);
      return () => clearInterval(id);
    }
  }, [delay]);

  useEffect(() => {
    if (startImmediate) {
      savedCallback.current();
    }
  }, []);
};

export default useInterval;
