/**
 * @name Work overview - Slide component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { memo } from "preact/compat";
import { useEffect, useState } from "preact/hooks";

import LineText from "@app/components/common/transitions/line-text";
import { ButtonPrimary } from "@app/components/portfolio/buttons";
import WorkIndicator from "@app/components/portfolio/ui/work-indicator";
import { isPointerCoarse } from "@app/utils/common/Screen";

import style from "./style.scss";

export interface IBlockOverview {
  current: number;
  description: string[];
  enableLeftArrow: boolean;
  enableRightArrow: boolean;
  innerRef?: any;
  state?: string;
  title_html: string[];
  total: number;
  url: string;
}

const WorkOverviewSlide: preact.FunctionalComponent<IBlockOverview> = ({
  current,
  description,
  enableLeftArrow,
  enableRightArrow,
  innerRef,
  state,
  title_html,
  total,
  url
}) => {
  const [show, set] = useState(false);

  const isTouch = isPointerCoarse();
  const ENTER_DELAY: number = isTouch ? 300 : 1000;

  // Only update if enter/update or leave
  useEffect(() => {
    set(state === "enter" || state === "update");
  }, [state]);

  return (
    <div className={style.article} ref={innerRef}>
      <div className="container fullheight">
        <div className="row fullheight">
          <div className={`col-md-6 ${style.inner}`}>
            {enableLeftArrow && (
              <div className={`${style.hit} ${style.hitLeft} js-hit-left`} />
            )}
            <div className={style.body}>
              <WorkIndicator
                current={current}
                show={show}
                delay={show ? ENTER_DELAY : 0}
                total={total}
              />
              <h2 className="line-text">
                <LineText
                  delay={show ? ENTER_DELAY + 200 : 100}
                  lines={title_html}
                  show={show}
                />
              </h2>
              <p className="line-text">
                <LineText
                  delay={show ? ENTER_DELAY + 400 : 200}
                  lines={description}
                  show={show}
                />
              </p>
              <ButtonPrimary
                delay={show ? ENTER_DELAY + 600 : 500}
                show={show}
                url={url}
                label="Find out more"
              />
            </div>
          </div>
          <div className={`col-md-6 ${style.inner} ${style.innerRight}`}>
            {enableRightArrow && (
              <div className={`${style.hit} ${style.hitRight} js-hit-right`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(WorkOverviewSlide);
