/**
 * @name Block - Video component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { Waypoint } from "react-waypoint";

import VideoPlayer from "@app/components/common/video-player";
import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockVideo {
  bg_color?: string;
  className?: string;
  placeholder?: string;
  url: string;
}

const BlockVideo: preact.FunctionalComponent<IBlockVideo & IWaypoint> = ({
  bg_color = "#000000",
  className,
  container,
  placeholder,
  url
}) => {
  //
  // State: Trigger playing video
  const [play, set] = useState(false);

  //
  // Event handler: Set background color for next project
  const enterHandler = useCallback(() => {
    set(true);
  }, []);

  // Event handler: Reset background color for current project
  const leaveHandler = useCallback(() => {
    set(false);
  }, []);

  //
  // If full width then make section
  if (className === "is-full-width") {
    return (
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        onLeave={leaveHandler}
        topOffset="25%"
        bottomOffset="25%"
      >
        <section
          className={`${style.section} ${style.isFullWidth}`}
          style={{ backgroundColor: bg_color }}
        >
          <VideoPlayer placeholder={placeholder} play={play} url={url} />
        </section>
      </Waypoint>
    );
  }

  // Otherwise wrap in div
  return (
    <Waypoint
      scrollableAncestor={container}
      onEnter={enterHandler}
      onLeave={leaveHandler}
      topOffset="25%"
      bottomOffset="25%"
    >
      <div
        className={`${style.section} ${style.isTwoCol}`}
        style={{ backgroundColor: bg_color }}
      >
        <VideoPlayer play={play} url={url} />
      </div>
    </Waypoint>
  );
};

export default BlockVideo;
