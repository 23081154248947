/**
 * @name Block - Quote component
 *
 * @version 1.0.0
 * @author Christopher Martin
 */

import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

import { IWaypoint } from "@app/components/common/waypoint";

import style from "./style.scss";

export interface IBlockQuote {
  text: string;
  caption: string;
}

// @ts-nocheck
const BlockQuote: preact.FunctionalComponent<IBlockQuote & IWaypoint> = ({
  container,
  text,
  caption
}) => {
  const [enabled, setEnabled] = useState(true);

  const [propsQuote, setQuote] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const [propsCaption, setCaption] = useSpring(() => ({
    opacity: 0,
    transform: "translate3d(0, 50px, 0)"
  }));

  const enterHandler = useCallback(() => {
    setEnabled(false);

    setQuote({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 150 });
    setCaption({ opacity: 1, transform: "translate3d(0, 0, 0)", delay: 250 });
  }, []);

  const BlockQuoteBody = useCallback(
    () => (
      <div className="container">
        <div className="row">
          <animated.div className={`col-12 ${style.block}`}>
            <blockquote style={propsQuote}>{text}</blockquote>
            <p style={propsCaption}>- {caption}</p>
          </animated.div>
        </div>
      </div>
    ),
    []
  );

  if (enabled) {
    return (
      <Waypoint
        scrollableAncestor={container}
        onEnter={enterHandler}
        bottomOffset="40%"
      >
        <section className={style.section}>
          <BlockQuoteBody />
        </section>
      </Waypoint>
    );
  }

  return (
    <section className={style.section}>
      <BlockQuoteBody />
    </section>
  );
};

export default BlockQuote;
