/**
 * @name Utils - dispatcher
 *
 * @description Taken from https://medium.com/@LeoAref/simple-event-dispatcher-implementation-using-javascript-36d0eadf5a11
 */

class Dispatcher {
  private events: any;

  constructor() {
    this.events = {};
  }

  public addListener(event: string, callback: (data?: any) => any) {
    // Create the event if not exists
    if (this.events[event] === undefined) {
      this.events[event] = {
        listeners: []
      };
    }
    this.events[event].listeners.push(callback);
  }

  public removeListener(event: string, callback: (data?: any) => any) {
    // Check if this event not exists
    if (this.events[event] === undefined) {
      return false;
    }
    this.events[event].listeners = this.events[event].listeners.filter(
      (listener: string) => {
        return listener.toString() !== callback.toString();
      }
    );
  }

  public dispatch(event: string, data?: any) {
    // Check if this event not exists
    if (this.events[event] === undefined) {
      return false;
    }
    this.events[event].listeners.forEach((listener: any) => {
      listener(data);
    });
  }
}

const dispatcher = new Dispatcher();

export default dispatcher;
